import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';


import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import NavbarLogo from '../../Assets/Images/Navbar Logo.png'



const Navbar = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate()

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    return ( 
        <Box sx={{ flexGrow: 1 }}>

            <AppBar position="static" sx={{ background: '#2E3B55'}}>

                <Container maxWidth='xl'>

                    <Toolbar>

                        <Box onClick={() => navigate('/')} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                            <img src={NavbarLogo} alt='logo' style={{ height: '4vh', margin: '0 1rem'}} />
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Open Sans', letterSpacing: '0.3rem' }}>
                                OPEND 
                            </Typography>
                        </Box>

                        <Box sx={{ flexGrow: 1, justifyContent:'flex-end', display: { xs: 'none', md: 'flex' } }}>
                       
                            <Button sx={{ m: 2, color: 'white', display: 'block' }} onClick={() => navigate('/about')}>
                                ABOUT
                            </Button>
                      

                            <Button sx={{ m: 2, color: 'white', display: 'block' }} onClick={() => navigate('/products')}>
                                PRODUCTS
                            </Button>

                            <Button sx={{ m: 2, mx:2, color: 'white', display: 'block' }} onClick={() => navigate('/contact')}>
                                CONTACT
                            </Button>

                            <Button sx={{ m: 2, mx:2, color: 'white', display: 'block' }} onClick={() => navigate('/blog')}>
                                BLOG
                            </Button> 

                        </Box>

                        <IconButton color="inherit" aria-label="open drawer" edge="start" sx={{ mx: 2, display: { xs: 'block', md: 'none' }}} onClick={toggleMenu} >
                            <MenuIcon />
                        </IconButton>

                        <Drawer anchor="right" open={isMenuOpen} onClose={toggleMenu} PaperProps={{ sx: { width: "50%", background: '#2E3B55' } }} >
                        
                                <Box display={'flex'} justifyContent={'flex-end'} padding={'1rem'}> 
                                    <IconButton
                                        color="inherit"
                                        aria-label="close drawer"
                                        edge="end"
                                        onClick={toggleMenu}
                                        sx={{ color: 'white'}}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>

                                <List sx={{ color: 'white' }}>

                                    <ListItemButton onClick={() => { toggleMenu() ; navigate('/about') }} >
                                        <ListItemText primary="ABOUT" />
                                    </ListItemButton>

                                    <ListItemButton onClick={() => { toggleMenu() ; navigate('/products') }}>
                                        <ListItemText primary="PRODUCTS" />
                                    </ListItemButton>

                                    <ListItemButton onClick={() => { toggleMenu() ; navigate('/contact') }}>
                                        <ListItemText primary="CONTACT" />
                                    </ListItemButton>

                                    <ListItemButton onClick={() => { toggleMenu() ; navigate('/blog') }}>
                                        <ListItemText primary="BLOG" />
                                    </ListItemButton>

                                </List>
                
                        </Drawer>

                    </Toolbar>

                </Container>

            </AppBar>
        
        </Box>
     );

}
export default Navbar;