import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import Explore from "../Components/Explore/Explore";
import { exploreSections } from "../Data/ExploreSections";

const ContactPage = () => {
    return ( 
        <>
            <Navbar />
            <Contact />
            <Explore {...exploreSections[0]} />
            <Footer />
        </>
     );
}
 
export default ContactPage;