import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';

const Explore = ({ header, route, subHeader, buttonText}) => {

    const navigate = useNavigate();


    const linkToPage = (e) => {
        e.preventDefault() ; 
        navigate(route) ; 
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }


    return ( 
        <Sheet variant='solid' sx={{ flexGrow: 1, display: 'flex', background:' #2E3B55',  overflow: 'hidden', p: { xs: '36px', md: '70px' }, pt: { xs: '24px', md: '60px' } }}>
         
                <Container maxWidth='lg'>

                    <Box sx={{ zIndex: 1, position: 'relative' }}>

                        <Typography level="h1" sx={{ color: 'white', fontWeight: '600', zIndex: 10}}> {header} </Typography>
                        <Typography sx={{ mt: 1, mb: 3, color: 'white'}}>
                            {subHeader}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                flexWrap: 'wrap',
                                maxWidth: 'max-content',
                                '& > *': { flexGrow: 1, fontWeight: 'lg' },
                            }}
                            >
                            
                            <Button
                                variant="plain"
                                endDecorator={<ArrowForwardIcon fontSize="md" />}
                                sx={{
                                '&:hover': { '--Button-gap': '0.625rem' },
                                '& span': { transition: '0.15s' },
                                background: 'white',
                                color: '#2E3B55',
                                zIndex: 10
                                }}
                                onClick={(e) => linkToPage(e)}
                            >
                                {buttonText}
                            </Button>

                            <Box
                            component="img"
                            alt=""
                            src="https://storage.googleapis.com/cms-storage-bucket/72521e62275b24d3c37d.png"
                            sx={{ position: 'absolute', height: {xs: '5vh', sm: '10vh', md: '15vh'}, top: 0, right: 0 }}
                            />


                        </Box>

                        

                    </Box>

                </Container>
          

        </Sheet>
     );
}
 
export default Explore;