export const exploreSections = [
    {
        header: 'Explore', 
        subHeader: 'Browse through our range of products',
        buttonText: 'Explore Products',
        route: '/products'

    },

    {
        header: 'About Us',
        subHeader: 'Learn more about our company and mission',
        buttonText: 'About Us',
        route: '/about'
    }, 
    
    {
        header: 'Services',
        subHeader: 'Explore our wide range of services',
        buttonText: 'View Services',
        route: '/services'
    }, 
    
    {
        header: 'Blog',
        subHeader: 'Read our latest blog posts',
        buttonText: 'Read Blog',
        route: '/blog'
    }, 
]