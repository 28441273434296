import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import blogs from './Data/Blogs';
import products from './Data/Products';

import Home from './Pages/Home';
import About from './Pages/About';
import ContactPage from './Pages/ContactPage';
import BlogList from './Pages/BlogList';
import BlogPage from './Pages/BlogPage';
import Products from './Pages/Products';
import Product from './Pages/Product';
import ServicePage from './Pages/Services';
import NotFound from './Pages/NotFound';




function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/> 
        <Route path='/about' element={<About />}/> 

        <Route path='/products' element={<Products />}/> 

        <Route path='/products/aluminum-shutters' element={<Product {...products[0]['subCategory'][0]} />}/> 
        <Route path='/products/galvanised-shutters' element={<Product {...products[0]['subCategory'][1]} />}/> 
        <Route path='/products/polycarbonate-shutters' element={<Product {...products[0]['subCategory'][2]} />}/> 
        <Route path='/products/fire-rated-shutters' element={<Product {...products[0]['subCategory'][3]} />}/> 

        <Route path='/products/gate-barriers' element={<Product {...products[1]['subCategory'][0]} />}/> 
        <Route path='/products/sliding-gates' element={<Product {...products[1]['subCategory'][1]} />}/> 
        <Route path='/products/swing-gates' element={<Product {...products[1]['subCategory'][2]} />}/> 
        <Route path='/products/palisade-fencing' element={<Product {...products[1]['subCategory'][3]} />}/> 

        <Route path='/products/garage-sectional-doors' element={<Product {...products[2]['subCategory'][0]} />}/> 
        <Route path='/products/industrial-sectional-doors' element={<Product {...products[2]['subCategory'][1]} />}/> 

        <Route path='/products/internal-high-speed-doors' element={<Product {...products[3]['subCategory'][0]} />}/> 
        <Route path='/products/external-high-speed-doors' element={<Product {...products[3]['subCategory'][1]} />}/> 
        <Route path='/products/fire-curtains' element={<Product {...products[3]['subCategory'][2]} />}/> 

        <Route path='/products/steel-swing-doors' element={<Product {...products[4]['subCategory'][0]} />}/> 
        <Route path='/products/fire-rated-steel-swing-doors' element={<Product {...products[4]['subCategory'][1]} />}/> 
        <Route path='/products/wooden-swing-doors' element={<Product {...products[4]['subCategory'][2]} />}/> 
        <Route path='/products/sliding-doors' element={<Product {...products[4]['subCategory'][3]} />}/>

        <Route path='/products/dock-shelters' element={<Product {...products[5]['subCategory'][0]} />}/> 
        <Route path='/products/dock-levelers' element={<Product {...products[5]['subCategory'][1]} />}/> 
        <Route path='/products/scissor-lifts' element={<Product {...products[5]['subCategory'][2]} />}/> 

        <Route path='/products/parking-management-systems' element={<Product {...products[6]['subCategory'][0]} />}/> 
        <Route path='/products/parking-guidance-systems' element={<Product {...products[6]['subCategory'][1]} />}/> 

        <Route path='/products/bollards' element={<Product {...products[7]['subCategory'][0]} />}/> 
        <Route path='/products/turnstiles' element={<Product {...products[7]['subCategory'][1]} />}/> 


        <Route path='/blog' element={<BlogList />}/> 

        <Route path='/blog/post-1' element={<BlogPage {...blogs[0]} />}/> 
        <Route path='/blog/post-2' element={<BlogPage {...blogs[1]} />}/> 
        <Route path='/blog/post-3' element={<BlogPage {...blogs[2]} />}/> 
        <Route path='/blog/post-4' element={<BlogPage {...blogs[3]} />}/> 
        <Route path='/blog/post-5' element={<BlogPage {...blogs[4]} />}/> 
        <Route path='/blog/post-6' element={<BlogPage {...blogs[5]} />}/> 



        <Route path='/contact' element={<ContactPage  {...blogs[6]} />}/> 

        <Route path='/services' element={<ServicePage />}/>

        <Route path="*" element={<NotFound />} /> 


      </Routes>
    
    </BrowserRouter>
  );
}

export default App;
