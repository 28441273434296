import Navbar from "../Components/Navbar/Navbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BlogCard from "../Components/BlogCard/BlogCard";
import Footer from "../Components/Footer/Footer";
import blogs from "../Data/Blogs";


const BlogList = () => {
    return ( 
        <>
            <Navbar />
   
            <Container maxWidth='lg' sx={{ p: 3, my: 5 }}>

                <Typography align="center" variant="h5"sx={{ my: 2}}> BLOGS </Typography>

                <Grid container spacing={2}>

                    {
                        blogs.map( blog => {
                            return(
                                <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <BlogCard {...blog} />
                                </Grid>
                            )
                        })
                    }
                       
               
                </Grid>
                
            </Container>
         
            <Footer />
        </>
     );
}
 
export default BlogList;