import Company from "../Components/Company/Company";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";

const About = () => {
    return ( 
        <>
        <Navbar/>
        <Company />
        <Footer />
        </>
     );
}
 
export default About;