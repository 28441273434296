import blogImage1 from '../Assets/Images/BlogPicture-1.jpg'
import blogImage2 from '../Assets/Images/BlogPicture-2.jpg'
import blogImage3 from '../Assets/Images/BlogPicture-3.jpg'
import blogImage4 from '../Assets/Images/BlogPicture-4.jpg'
import blogImage5 from '../Assets/Images/BlogPicture-5.jpg'
import blogImage6 from '../Assets/Images/BlogPicture-6.jpg'

const blogs = [
  {
    id: 1,
    title: "Exploring the Benefits of Roller Shutters",
    cardDescription: "Enhance Security and Convenience with Roller Shutters",
    date: "October 12, 2023",
    paragraph1: "Roller shutters offer a multifaceted solution for both residential and commercial spaces. Firstly, they excel in providing enhanced security. These sturdy and robust shutters act as an effective deterrent against intruders and vandals, making your property a fortress of safety. Additionally, roller shutters are versatile in terms of usage. They can be adjusted to control the amount of natural light entering your space, thus providing a comfortable indoor environment. Moreover, roller shutters contribute to energy efficiency by insulating your property, reducing heat loss in colder months, and keeping interiors cooler during hot weather. This not only lowers your energy bills but also aligns with sustainable living practices.",
    paragraph2: "Sensor-equipped automatic sliding doors enhance safety and convenience. They can detect the presence of people, preventing accidental collisions. This technology also reduces the spread of germs by minimizing the need for physical contact with door handles. In summary, automatic sliding doors combine convenience, energy efficiency, and safety, making them a practical choice for various settings.",
    img: blogImage1,
    description: [
       {
          title: "Enhanced Security",
          paragraph: "Roller shutters are a reliable security solution for homes and businesses. They act as a strong physical barrier against break-ins and vandalism. Their robust construction and locking mechanisms make it challenging for intruders to gain access, providing peace of mind to property owners."
       },
       {
          title: "Privacy and Light Control",
          paragraph: "Roller shutters offer versatile control over privacy and natural light. You can adjust them to allow in as much or as little sunlight as you desire, while also maintaining your privacy. This feature is particularly valuable in residential spaces and offices."
       },
       {
          title: "Energy Efficiency",
          paragraph: "Roller shutters contribute to energy efficiency in multiple ways. They act as effective insulators, reducing heat transfer during hot summers and keeping interiors warmer in winter. This insulation helps lower energy bills by reducing the need for excessive heating and cooling."
       },
       {
          title: "Weather Protection",
          paragraph: "Roller shutters are excellent at shielding against adverse weather conditions. They provide protection against heavy rain, strong winds, and even hail. This weather resistance helps safeguard your property's exterior and interior from damage."
       },
       {
          title: "Noise Reduction",
          paragraph: "For those living in noisy environments, roller shutters offer a significant benefit. They act as a sound barrier, reducing external noise infiltration. This can lead to a quieter and more peaceful living or working environment."
       },
       {
          title: "Increased Property Value",
          paragraph: "Investing in roller shutters can add value to your property. Potential buyers and renters often view them as desirable features due to the security and energy-saving benefits they offer. Installing roller shutters can make your property more attractive in the real estate market."
       }
    ]
 },
 {
  id: 2,
  title: "The Convenience of Automatic Sliding Doors",
  cardDescription: "Seamless Entry and Energy Efficiency",
  date: "December 18, 2021",
  paragraph: " Automatic sliding doors revolutionize accessibility in high-traffic environments. These doors offer a seamless entry and exit experience, making them ideal for bustling places like shopping malls, hospitals, and airports. Their convenience extends beyond physical access, as they also contribute to energy efficiency. By minimizing air exchange and preserving indoor temperatures, automatic sliding doors aid in maintaining a comfortable environment while reducing heating and cooling costs. Additionally, these doors feature sensor technology that enhances safety by detecting individuals' presence, preventing collisions, and minimizing the need for physical contact, which is especially valuable in health-conscious times. In summary, automatic sliding doors are a combination of convenience, energy efficiency, and safety, making them a practical choice for various settings. ",
  img: blogImage2,
  description: [
     {
        title: "Effortless Entry and Exit",
        paragraph: "Automatic sliding doors are a testament to modern convenience. They offer seamless entry and exit in high-traffic areas such as shopping centers, airports, and hospitals. Whether you're carrying bags or have your hands full, these doors make accessibility easy and hassle-free."
     },
     {
        title: "Energy Efficiency",
        paragraph: "Beyond convenience, automatic sliding doors contribute to energy efficiency. They minimize air exchange, helping to maintain a comfortable indoor environment. This energy-saving feature not only benefits the environment but also leads to cost savings for businesses."
     },
     {
        title: "Space-Saving Design",
        paragraph: "Automatic sliding doors are ideal for spaces with limited room for traditional swinging doors. They slide horizontally along the wall, maximizing usable space. This design makes them a practical choice for various architectural layouts."
     },
     {
        title: "Sensor Technology",
        paragraph: "Sensor-equipped automatic sliding doors enhance safety and convenience. They can detect the presence of people, preventing accidental collisions. This technology also reduces the spread of germs by minimizing the need for physical contact with door handles."
     },
     {
        title: "Customization Options",
        paragraph: "Automatic sliding doors come in various designs and materials to suit different architectural styles. Whether you prefer a sleek, modern look or a more traditional aesthetic, there are customization options available to match your preferences."
     },
     {
        title: "Accessibility Compliance",
        paragraph: "Automatic sliding doors are designed to comply with accessibility standards, making buildings more inclusive. They provide easy access for individuals with disabilities, ensuring that everyone can enter and exit a building comfortably."
     }
  ]
}
,
{
  id: 3,
  title: "Steel Push Doors: A Shield of Security",
  cardDescription: "Strength, Durability, and Safety Combined",
  paragraph: "Steel push doors stand as an impervious fortress of security, offering robust protection for a wide range of applications. Crafted from durable materials and designed to resist forced entry attempts, these doors are the first line of defense for properties requiring uncompromising security. Commercial establishments, industrial facilities, and sensitive areas benefit immensely from the unyielding strength of steel push doors. Beyond security, these doors possess impressive longevity, capable of withstanding harsh environmental conditions and daily wear. Their resistance to corrosion and wear ensures they remain reliable guardians for an extended period. When it comes to safeguarding assets and maintaining peace of mind, steel push doors stand as an impenetrable shield of security, offering a combination of durability, strength, and peace of mind that property owners can rely on.",
  date: "May 5, 2018",
  img: blogImage3,
  description: [
     {
        title: "Unwavering Strength",
        paragraph: "Steel push doors are the embodiment of security and resilience. Crafted from robust materials, they stand as formidable barriers against unauthorized access. Their strength provides peace of mind to property owners and businesses, knowing that their assets are well-protected."
     },
     {
        title: "Durable Design",
        paragraph: "Built to withstand the test of time, steel push doors offer long-lasting durability. They are resistant to wear, corrosion, and the elements, making them an ideal choice for environments exposed to harsh weather conditions."
     },
     {
        title: "Enhanced Security Features",
        paragraph: "Steel push doors often come equipped with advanced security features such as reinforced locking mechanisms and access control systems. These additional layers of security further fortify the integrity of these doors."
     },
     {
        title: "Versatile Applications",
        paragraph: "From commercial facilities to industrial warehouses, steel push doors find versatile applications. Their adaptability makes them suitable for a wide range of settings, ensuring security and protection wherever they are installed."
     },
     {
        title: "Customization Options",
        paragraph: "Property owners have the flexibility to customize steel push doors to match their specific security requirements and aesthetic preferences. From finishes to additional security features, these doors offer customization possibilities."
     },
     {
        title: "Peace of Mind",
        paragraph: "Choosing steel push doors means choosing peace of mind. These doors provide not only a physical shield of security but also the psychological comfort of knowing that your property is safeguarded against threats."
     }
  ]
}
,
{
  id: 4,
  title: "Industrial Sectional Overhead Doors: Maximizing Efficiency",
  cardDescription: "Streamlined Operations and Enhanced Security",
  paragraph: "Industrial sectional overhead doors are the unsung heroes of efficient operations in warehouses and industrial settings. With their efficient opening and closing mechanisms, these doors facilitate the seamless flow of goods, reducing downtime and enhancing productivity. This streamlined operation is vital for industries where time equals money, making these doors indispensable. Moreover, industrial sectional overhead doors contribute to energy efficiency by maintaining temperature control within the facility. They act as reliable insulators, minimizing heat loss during colder months and preventing excessive heat gain in the scorching summer heat. As a result, these doors help reduce energy costs and lessen the environmental impact. Additionally, their durability and resistance to wear and tear ensure they remain dependable over the long term. Customization options further enhance their versatility, making them ideal for a wide range of applications. Industrial sectional overhead doors are truly instrumental in maximizing efficiency and productivity across various industries.",
  date: "January 15, 2024",
  img: blogImage4,
  description: [
     {
        title: "Optimized Operations",
        paragraph: "Industrial sectional overhead doors are a crucial component in streamlining operations in warehouses and industrial facilities. Their efficient opening and closing mechanisms facilitate the smooth flow of goods, reducing downtime and improving productivity."
     },
     {
        title: "Energy Efficiency",
        paragraph: "These doors are designed with energy efficiency in mind. Their insulated panels help maintain temperature control within the facility, reducing heating and cooling costs. This energy-saving feature contributes to sustainability and cost-effectiveness."
     },
     {
        title: "Enhanced Security",
        paragraph: "Security is paramount in industrial settings, and sectional overhead doors provide robust protection. Their sturdy construction and advanced locking systems deter unauthorized access, safeguarding valuable assets and inventory."
     },
     {
        title: "Space Optimization",
        paragraph: "Industrial sectional doors maximize available space. They open vertically, eliminating the need for clearance space required by swinging doors. This space-saving design allows for more efficient use of floor space."
     },
     {
        title: "Durability and Longevity",
        paragraph: "Built to endure heavy usage, these doors are known for their durability and long lifespan. Their resistance to wear and tear ensures that they can withstand the demands of industrial environments for years to come."
     },
     {
        title: "Customized Solutions",
        paragraph: "Every industrial facility is unique, and sectional overhead doors offer customizable solutions. From size and insulation options to specialized security features, these doors can be tailored to meet specific needs."
     }
  ]
}
,
{
  id: 5,
  title: "Elevate Your Garage with Stylish Garage Doors",
  cardDescription: "Aesthetic Appeal and Functionality Combined",
  paragraph: "Garage doors are not merely functional; they play a significant role in a home's aesthetic appeal. Stylish garage doors have the power to transform the curb appeal of your property. Available in a wide range of designs, colors, and materials, these doors allow you to choose a style that complements and enhances your home's architectural character. Beyond aesthetics, modern garage doors offer smart features that add an extra layer of functionality and convenience to your daily life. They provide insulation that helps maintain temperature control within your garage, reducing energy bills and making your space more energy-efficient. Furthermore, stylish garage doors aren't just about looks; they also add tangible value to your property. Potential buyers and appraisers often view them as desirable features, making your home more attractive in the competitive real estate market. Elevate your garage with stylish doors that seamlessly combine aesthetic appeal with practical functionality, enhancing both the form and function of your property.",
  date: "July 21, 2021",
  img: blogImage5,
  description: [
     {
        title: "Curb Appeal Enhancement",
        paragraph: "Garage doors are a significant part of a home's facade. Stylish garage doors can instantly elevate your home's curb appeal. They come in various designs, colors, and materials, allowing you to choose a style that complements your home's architecture."
     },
     {
        title: "Customized Options",
        paragraph: "Personalization is key when it comes to garage doors. You can select from a range of customization options, including panel designs, window placements, and decorative hardware. Tailor your garage door to match your unique taste and preferences."
     },
     {
        title: "Smart and Functional",
        paragraph: "Modern garage doors offer more than just aesthetics. Many come with smart features that allow you to control them remotely. Additionally, they provide enhanced insulation, security, and convenience for your garage space."
     },
     {
        title: "Durability and Longevity",
        paragraph: "Investing in stylish garage doors means investing in durability. These doors are built to withstand the elements and daily use, ensuring they maintain their appeal and functionality for years to come."
     },
     {
        title: "Value Addition",
        paragraph: "Stylish garage doors not only enhance the appearance of your home but also add value to your property. Potential buyers and appraisers often view them as desirable features, making your home more attractive in the real estate market."
     },
     {
        title: "Versatile Styles",
        paragraph: "From classic carriage-style doors to sleek modern designs, there's a wide range of garage door styles to choose from. Explore various options to find the perfect door that suits your home's character and personality."
     }
  ]
}
,
{
  id: 6,
  title: "Palisade Fencing: A Robust Security Solution",
  cardDescription: "Security, Durability, and Aesthetic Appeal",
  paragraph: "Palisade fencing is a robust security solution that combines formidable protection with modern aesthetics. The sharp-edged steel pales and imposing design serve as powerful deterrents against intruders, establishing a formidable security barrier for properties. While security is paramount, palisade fencing doesn't compromise on visual appeal. Its sleek and modern design seamlessly complements various architectural styles, making it a visually appealing choice for discerning property owners. The versatility of palisade fencing is another standout feature. It adapts effortlessly to various applications, including residential properties, commercial establishments, and industrial sites. Its customization options allow property owners to tailor their fencing solutions to meet specific security and aesthetic preferences. Furthermore, palisade fencing is constructed with durability in mind. It can withstand harsh weather conditions, ensuring that it remains sturdy and reliable over the long term. In summary, palisade fencing is a security solution that delivers unmatched protection without sacrificing aesthetics, making it a versatile and dependable choice for those seeking robust security solutions for their properties.",
  date: "March 15, 2020",
  img: blogImage6,
  description: [
     {
        title: "Superior Security",
        paragraph: "Palisade fencing is renowned for its unmatched security capabilities. The sharp-edged steel pales and intimidating appearance serve as strong deterrents against intruders, providing a robust security barrier for properties."
     },
     {
        title: "Durable Construction",
        paragraph: "Built to withstand the test of time and harsh environmental conditions, palisade fencing is highly durable. Its corrosion-resistant materials ensure it remains sturdy and reliable, even in challenging weather conditions."
     },
     {
        title: "Aesthetic Appeal",
        paragraph: "While primarily a security solution, palisade fencing doesn't compromise on aesthetics. Its sleek and modern design complements various architectural styles, making it a visually appealing choice for property owners."
     },
     {
        title: "Customization Options",
        paragraph: "Palisade fencing offers customization options to suit individual needs. You can choose from different heights, pale profiles, and finishes to create a fencing solution that aligns with your specific security and aesthetic preferences."
     },
     {
        title: "Low Maintenance",
        paragraph: "Once installed, palisade fencing requires minimal maintenance. Its materials are resistant to rust and corrosion, and occasional cleaning is all that's needed to keep it looking great and functioning effectively."
     },
     {
        title: "Versatile Applications",
        paragraph: "Palisade fencing is versatile and suitable for various applications, including residential properties, commercial establishments, and industrial sites. Its adaptability makes it a popular choice for a wide range of security needs."
     }
  ]
}
,
    
  ];


export default blogs

