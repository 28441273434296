import { Container, Typography, Grid, Divider, Box } from "@mui/material";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import products from "../Data/Products";



const Products = () => {

    return ( 
        <>
            <Navbar/>

            <Container maxWidth='xl' sx={{ p: 3, my: 5 }}>
                
                <Typography align="center" variant="h5"sx={{ my: 2, fontFamily: 'Open Sans', letterSpacing: '0.1rem', fontWeight: '600'}}> PRODUCTS </Typography>

                    {
                        products.map( product => {
                            return (
                                <Box sx={{ my: 5}}>
                                    <Typography align="inherit" variant="h5" sx={{ my: 1, letterSpacing: '0.1rem', fontWeight: '500'}}> {product.category} </Typography>

                                    <Divider sx={{ mb: 2 }} />

                                    <Grid container spacing={2} sx={{ justifyContent: { xs: 'center', md: 'left'}}} alignItems='center' >
                                        {
                                            product.subCategory.map( item => {
                                                return (
                                                    <Grid item key={item.id}>
                                                        <ProductCard {...item} />
                                                    </Grid>
                                                )
                                            })
                                        
                                        }
                                    </Grid>
                                </Box>
                            )
                        })
                    }

                    

            </Container>

            <Footer />

        </>
     );
}
 
export default Products;