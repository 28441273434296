import { Container, Typography, Box } from "@mui/material";
import services from "../../Data/Services";

const Services = () => {


    return ( 
        <Container maxWidth='xl' sx={{ my: 5 }}>
            
                {
                    services.map(section => {
                        return(
                            <Box sx={{ display: 'flex', my: 5 , flexDirection: { xs: 'column', md: 'row'}, borderBottom: '1px solid lightgrey'}}>
                                <Box>

                                    <Typography variant="h5" align="center" gutterBottom sx={{ my: 2, letterSpacing: '0.1rem', textTransform: 'uppercase', fontWeight: '500' }}>
                                        {section.sectionName}
                                    </Typography>

                                    {
                                        section.points.map(point => {
                                            return(
                                                <Box sx={{  m: {xs: 3, md: 2} }}>
                                                    <Typography variant="h6" sx={{ my: 2 }} >
                                                        {point.title}
                                                    </Typography>

                                                    <Typography variant="body1" sx={{ textAlign: 'justify' }} paragraph>
                                                        {point.description}
                                                    </Typography>

                                                </Box>    
                                            )
                                        })
                                    }

                                    

                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4, ml: { md: 5}}}>
                                    <img src={section.image} alt='service-section' style={{ height: '45vh', borderRadius: '2vh' }} />    
                                </Box>
                            </Box>
                        )
                    })
                }

            
        </Container>
     );
}
 
export default Services;