import Navbar from "../Components/Navbar/Navbar";
import Services from "../Components/Services/Services";
import Explore from "../Components/Explore/Explore";
import Footer from "../Components/Footer/Footer";
import { exploreSections } from "../Data/ExploreSections";

const ServicePage = () => {
    return ( 
        <>
            <Navbar />
            <Services />
            <Explore {...exploreSections[0]} />
            <Footer />
        </>
     );
}
 
export default ServicePage;