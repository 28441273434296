import Sheet from "@mui/joy/Sheet";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box  from "@mui/material/Box";
import Container from "@mui/material/Container";




const Choose = ({ heading, points}) => {
    return ( 
        <Sheet variant="solid" sx={{ flexGrow: 1, py: 2 }} color='lightgrey'>

            <Container maxWidth='xl' sx={{ py: 3}}>

                
                <Typography variant="h4" align="center"> {heading} </Typography>
                

                <Box display={"flex"} justifyContent={"space-around"} alignItems={'center'} sx={{ flexDirection: { xs: 'column', md: 'row' }}} gap={2}>
                    

                    {
                        points.map( point => {
                            return(
                                <Card key={point.id} sx={{ maxWidth: 345, cursor: 'pointer', my: 3, 
                                            transition: 'transform 0.5s, box-shadow 0.5s', '&:hover': { transform: 'scale(1.03)', boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.3)'} }}>
                                    <CardMedia
                                        sx={{ height: 150 }}
                                        image={point.img}
                                        title="Craftmanship"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {point.heading}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {point.paragraph}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }

                </Box>

            </Container>

        </Sheet>
     );
}
 
export default Choose;