import Navbar from './../Components/Navbar/Navbar';
import Hero from "../Components/Hero/Hero";
import Explore from "../Components/Explore/Explore";
import Choose from "../Components/Choose/Choose";
import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";

import { exploreSections } from "../Data/ExploreSections";
import choice from "../Data/Choose";


const Home = () => {
    return ( 
        <>
            <Navbar/>
            <Hero />
            <Explore {...exploreSections[0]} />
            <Choose {...choice[0]} />
            <Explore {...exploreSections[1]} />
            <Choose {...choice[1]} />
            <Explore {...exploreSections[2]} />
            <Contact />
            <Explore {...exploreSections[3]} />
            <Footer />
        </>
     );
}
 
export default Home;