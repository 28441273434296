import Footer from "../Components/Footer/Footer";
import ProductDetails from "../Components/ProductDetails/ProductDetails";
import Navbar from './../Components/Navbar/Navbar';

const Product = (props) => {
    return ( 
        <>
            <Navbar />
            <ProductDetails {...props} />
            <Footer />
        </>
     );
}
 
export default Product;