import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Blog = ({title, img, date, paragraph, description }) => {

    const isSmallScreen = useMediaQuery('(max-width:900px)');

    const bigScreenStyling = {
        maxWidth: '80%', height: '40vh'
    }

    const smallScreenStyling = {
        maxWidth: '100%', height: '30vh'
    }

    const imageStyling = isSmallScreen ? smallScreenStyling : bigScreenStyling;

    return ( 
        <Container maxWidth='lg'>

            <Paper elevation={3} style={{ padding: '20px', margin: '40px 0' }}>
                <Typography variant="h5" align="center" gutterBottom sx={{ my: 5 }}>
                    {title}
                </Typography>

                <Grid container spacing={3}>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={img} alt="blog-img" style={imageStyling} />
                    </Grid>

                    <Grid item xs={12}>

                        <Typography variant="body1" paragraph>
                            {paragraph}
                        </Typography>

                    </Grid>

                    <Grid item xs={12}>

                        { description.map( d => {
                            return(
                                <Box sx={{ my: 4 }}>
                                    <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.2rem', letterSpacing: '0.1rem' }}>
                                        {d.title}
                                    </Typography>
                                    <Typography variant="body1" paragraph>
                                        {d.paragraph}
                                    </Typography>
                                </Box>
                            )
                        } )}

                    </Grid>

                    

                    <Grid item xs={12}>
                        <Divider sx={{ mb: 2}} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <CalendarMonthIcon sx={{ mr: 2}} />
                            <Typography variant="caption" color="text.secondary">
                                {date}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>

            </Paper>

        </Container>
     );
}
 
export default Blog;