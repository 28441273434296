import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';
import Blog from '../Components/Blog/Blog';


const BlogPage = (props) => {
    return ( 
        <>
        <Navbar />
        <Blog {...props} />
        <Footer />
        </>
     );
}
 
export default BlogPage;