import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";



const ProductCard = ({ name, route, cardImg, cardDescription }) => {

    const navigate = useNavigate();

    const linkToPage = (e) => {
        e.preventDefault() ; 
        navigate(route) ; 
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return ( 
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 140 }}
                image={cardImg}
                title="card-image"
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    {name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {cardDescription}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={(e) => linkToPage(e)}> Explore</Button>
            </CardActions>
        </Card>

     );
}
 
export default ProductCard;