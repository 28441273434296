import Sheet from '@mui/joy/Sheet';
import Carousel from 'react-bootstrap/Carousel';
import Container from '@mui/material/Container';
import heroSections from '../../Data/Hero';
import useMediaQuery from '@mui/material/useMediaQuery';


const Hero = () => {

    const isSmallScreen = useMediaQuery('(max-width:900px)');

    const bigScreenStyling = {
        width: '100%', 
        height: '70vh',
        borderRadius: '10px', 
        objectFit: 'cover'
    }

    const smallScreenStyling = {
        width: '100%', 
        height: '40vh',
        borderRadius: '10px', 
        objectFit: 'cover'
    }

    const imageStyling = isSmallScreen ? smallScreenStyling : bigScreenStyling;

    

    return ( 
        <Sheet variant="solid" sx={{ flexGrow: 1, p: 2 }} color='lightgrey'>

            <Container maxWidth='xl' sx={{ px: 4 }}>

                <Carousel>

                    {
                        heroSections.map(section => {
                            return(
                                <Carousel.Item key={section.id}>
                                    <img src={section.img} 
                                        style={imageStyling} alt='img-1' />
                                    <Carousel.Caption>
                                    <h3> {section.header} </h3>
                                    <p> {section.subHeader} </p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        })
                    }


                </Carousel>

            </Container>

        </Sheet>
     );
}
 
export default Hero;