import HeroImage1 from '../Assets/Images/Hero-Image-01.jpg'
import HeroImage2 from '../Assets/Images/Hero-Image-02.jpg'
import HeroImage3 from '../Assets/Images/Hero-Image-03.jpg'
import HeroImage4 from '../Assets/Images/Hero-Image-04.jpg'
import HeroImage5 from '../Assets/Images/Hero-Image-05.jpg'

const heroSections = [
    {
        id: "01",
        header: "Welcome to Opend",
        subHeader: "Based in the UAE",
        img: HeroImage1
    },
    {
        id: "02",
        header: "Quality Products",
        subHeader: "Tailored Solutions",
        img: HeroImage2
    },
    {
        id: "03",
        header: "Exceptional Service",
        subHeader: "Dedicated Support",
        img: HeroImage3
    },
    {
        id: "04",
        header: "Innovation and Technology",
        subHeader: "Cutting-Edge Solutions",
        img: HeroImage4
    },
    {
        id: "05",
        header: "Serving Diverse Industries",
        subHeader: "Construction, Manufacturing, and More",
        img: HeroImage5
    }
]


export default heroSections