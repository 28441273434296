import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/material/Link';

import Container from '@mui/material/Container';
import blogs from '../../Data/Blogs';
import { useNavigate } from 'react-router-dom';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';






const Footer = () => {

    const blogObj = blogs[Math.floor(Math.random() * blogs.length)];
    const navigate = useNavigate();

    const linkToPage = (e, route) => {
        e.preventDefault() ; 
        navigate(route) ; 
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return ( 
        <Sheet variant="solid" sx={{ p: 2, flexGrow: 1 }} color='lightgrey'>

            

            <Container maxWidth='xl'>
            
                <Box variant="solid" color={'#2E3B55'} invertedColors sx={{ flexGrow: 1, p: 2}}>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: {xs: 'center', md: 'flex-end'}, gap: 2, mx: 5 }}>

                        <Link href='https://www.linkedin.com/in/opend-entrance-systems-8653ab2a5/' target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none'}}>
                            <IconButton variant="plain">
                                <LinkedInIcon />
                            </IconButton>
                        </Link>

                        <Link href='https://www.facebook.com/people/Opend-Entrance-Systems/61554669312692/?mibextid=LQQJ4d' target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none'}}>
                            <IconButton variant="plain">
                                <FacebookRoundedIcon />
                            </IconButton>
                        </Link>

                        <Link href='https://www.instagram.com/opendentrance_systems/?igsh=MTdpYTJndHl6ZmN3bQ%3D%3D&utm_source=qr' target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none'}}>
                            <IconButton variant="plain">
                                <InstagramIcon />
                            </IconButton>
                        </Link>

                        <Link href='https://twitter.com/opendentsys?s=21' target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none'}}>
                            <IconButton variant="plain">
                                <TwitterIcon />
                            </IconButton>
                        </Link>

                        <Link href='https://www.youtube.com/@OpendEntranceSystems' target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none'}}>
                            <IconButton variant="plain">
                                <YouTubeIcon />
                            </IconButton>
                        </Link>

                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { md: 'flex-start' }, justifyContent: 'space-between', flexWrap: 'wrap',  gap: 2 }} >
            
                        <Card variant="soft" size="lg" sx={{ flexDirection: 'column' , alignContent: 'center', justifyContent: 'center', maxWidth: '300', cursor: 'pointer', gap: 1 }} onClick={(e) => linkToPage(e, '/blog')}>

                            <AspectRatio ratio="21/9" minHeight={80} sx={{ flexBasis: { xs: 100, md: 'initial' } }} >
                                <img alt="blog-picture" src={blogObj.img} />
                            </AspectRatio>

                            <CardContent>

                                <Typography level="body-sm"> {blogObj.title} </Typography>

                                <Typography level="body-xs" sx={{ mb: 0.5 }}>
                                    Read more...
                                </Typography>

                            </CardContent>

                        </Card>

                        <List size="sm" orientation="horizontal" wrap sx={{ flexGrow: 0, '--ListItem-radius': '8px' }} >
                            <ListItem nested sx={{ width: { xs: '50%', md: 140 } }}>
                                <ListSubheader sx={{ fontWeight: 'bold'}}> EXPLORE OPEND </ListSubheader>
                                    <List>
                                        <ListItem onClick={ (e) => linkToPage(e, '/about')}>
                                            <ListItemButton>About</ListItemButton>
                                        </ListItem>

                                        <ListItem onClick={ (e) => linkToPage(e, '/products')}>
                                            <ListItemButton>Products</ListItemButton>
                                        </ListItem>

                                        <ListItem onClick={ (e) => linkToPage(e, '/services')}>
                                            <ListItemButton>Services</ListItemButton>
                                        </ListItem>

                                        <ListItem onClick={ (e) => linkToPage(e, '/contact')}>
                                            <ListItemButton>Contact</ListItemButton>
                                        </ListItem>
                                    </List>
                            </ListItem>
            
                            <ListItem nested sx={{ width: { xs: '50%', md: 180 } }}>
                                <ListSubheader sx={{ fontWeight: 'bold'}}> PRODUCTS </ListSubheader>
                                    <List>
                                        <ListItem onClick={ (e) => linkToPage(e, '/products')}>
                                            <ListItemButton>Roller Shutters </ListItemButton>
                                        </ListItem>

                                        <ListItem onClick={ (e) => linkToPage(e, '/products/garage-sectional-doors')}>
                                            <ListItemButton> Garage Doors </ListItemButton>
                                        </ListItem>

                                        <ListItem onClick={ (e) => linkToPage(e, '/products/gate-barriers')}>
                                            <ListItemButton> Gate Barriers </ListItemButton>
                                            <Chip variant="soft" size="sm" sx={{ minHeight: 20, fontSize: 'xs2', ml: 'auto' }}>
                                                NEW
                                            </Chip>
                                        </ListItem>

                                        <ListItem onClick={ (e) => linkToPage(e, '/products/sliding-gates')}>
                                            <ListItemButton> Sliding Gates </ListItemButton>
                                        </ListItem>

                                        <ListItem onClick={ (e) => linkToPage(e, '/products/parking-management-systems')}>
                                            <ListItemButton> Parking Systems </ListItemButton>
                                            <Chip variant="soft" size="sm" sx={{ minHeight: 20, fontSize: 'xs2', ml: 'auto' }}>
                                                NEW
                                            </Chip>
                                        </ListItem>

                                    </List>
                            </ListItem>
            
                        </List>

                    </Box>

                    <Divider sx={{ my: 2 }} />
                            
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { md: 'flex-start' }, justifyContent: 'space-between', flexWrap: 'wrap',  gap: 2, px: 3 }}>
                        <Typography variant="caption" display="block" gutterBottom sx={{ fontSize: '14px'}}>
                            Copyright © {new Date().getFullYear()} Opend. All rights reserved.
                        </Typography>

                        <Link href={`mailto:abishekmanojx@gmail.com`} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none'}}>
                            <Typography variant="caption" display="block" gutterBottom sx={{ fontSize: '14px'}}> 
                                Designed by IoWeb
                            </Typography>
                        </Link>
                    </Box>


                </Box>

            </Container>

        </Sheet>
        
     );
}
 
export default Footer;