import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useNavigate } from 'react-router-dom';
import NavbarLogo from '../Assets/Images/Navbar Logo.png'
import Footer from '../Components/Footer/Footer';
import notFoundImage from '../Assets/Images/Not Found.png'


const NotFound = () => {

    const navigate = useNavigate()

    return ( 
       <Box>
            <AppBar position="static" sx={{ background: '#2E3B55'}}>
                <Container maxWidth='xl'>
                    <Toolbar>
                            <Box onClick={() => navigate('/')} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                                <img src={NavbarLogo} alt='logo' style={{ height: '4vh', margin: '0 1rem'}} />
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Open Sans', letterSpacing: '0.3rem' }}>
                                    OPEND 
                                </Typography>
                            </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Container maxWidth='lg' sx={{ my: 5, textAlign: 'center' }}>

                <img src={notFoundImage} alt='404-not- found-' style={{ height: '200px', margin: '0 20px' }} />

                <Typography variant="h4" component="div" sx={{ flexGrow: 1, fontFamily: 'Open Sans', letterSpacing: '0.1rem', fontSize: { xs: '20px', md: '30px' }  }}>
                    UH, OH. THE PAGE YOU ARE SEARCHING FOR DOES NOT EXIST.
                </Typography> 

                <Typography variant="h4" component="div" sx={{ flexGrow: 1, my: 5, fontFamily: 'Open Sans', letterSpacing: '0.1rem', fontSize: { xs: '20px', md: '30px' } }}>
                    ERROR STATUS : 404
                </Typography>

                <Button variant="contained" endIcon={<DirectionsIcon />} onClick={() => navigate('/')}>
                    Redirect
                </Button>
                
            </Container>

            <Footer />
       </Box> 
     );
}
 
export default NotFound;