import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Sheet } from '@mui/joy';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  

  const handleWhatsAppButtonClick = () => {
    const phoneNumber = '971585850905';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, '_blank');
  };

  const handleSubmit = () => {
    try {
 
      if (firstName === '' && lastName === '' && email ===''  && phone ==='' && message === '') {
        toast.error("Please fill out all the required contact fields.");
      } else if(!email.includes('@') || !email.includes('.')){
        toast.error("Enter a valid email.");
      } else {
        setFirstName(''); setLastName(''); setEmail(''); setPhone(''); 
        toast.error("Internal Server error. Please contact us via live WhatsApp chat or email. Apologies for the inconvenience.", { autoClose: 12000});
      }
      
    } catch (error) {
      toast.error("Failed to send the message. Please try our live WhatsApp chat or email us."); 
    }
  }

  return (
    <Sheet variant="solid" sx={{ p: 5, flexGrow: 1 }} color='lightgrey' >

      <ToastContainer />

      <Container maxWidth="lg">

        <Grid container spacing={3}>

          {/* Display Section (Left Side) */}
          <Grid item xs={12} md={6}>

            <Typography variant="h5" style={{ paddingBottom: '1rem'}}>
              Contact Information
            </Typography>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
              <LocationOnIcon style={{ color: '#2E3B55', marginRight: '1rem' }} />
              <Typography variant="body1">
                Location: RAK, United Arab Emirates
              </Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
              <EmailIcon style={{ color: '#2E3B55', marginRight: '1rem' }} size="large" />
              <a href = "mailto: sales@openedentrancesystems.com" style={{ color: 'black', textDecoration: 'none', '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
                sales@opendentrancesystems.com
              </a>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
              <LocalPhoneIcon style={{ color: '#2E3B55', marginRight: '1rem' }} />
              <Typography variant="body1">
                Phone: +971 58 585 0905 | +971 58 5880817
              </Typography>
            </div>


            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
              <WhatsAppIcon style={{ color: '#25d366', marginRight: '1rem' }} />
              <Button variant="outlined" color="success" onClick={handleWhatsAppButtonClick}>
                 Live Whatsapp Chat
              </Button>
            </div>

          </Grid>

          {/* Contact Form (Right Side) */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" style={{ paddingBottom: '1rem'}}>
              Contact Us
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    multiline
                    rows={4}
                    variant="outlined"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" color="primary" onClick={handleSubmit}>
                    Send
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Sheet>
  );
};

export default Contact;
