import AIS from '../Assets/Images/MI - Aluminum Single Skin Roller Shutter.jpg'
import ALSS from '../Assets/Images/MI - Aluminum Insulated Roller Shutter.jpg'
import GISS from '../Assets/Images/MI - GI Single Skin Roller Shutters.jpg'
import GIIS from '../Assets/Images/MI - GI Insulated Roller Shutters.jpg'
import GIPRS from '../Assets/Images/MI - GI Perforated Roller Shutter.jpg'
import PCRS from '../Assets/Images/MI - Polycarbonate Roller Shutters.jpg'
import FRSS from '../Assets/Images/MI - GI Fire Rated Single Skin Roller Shutters.jpg'
import FRIS from '../Assets/Images/MI - GI Fire Rated Insulated Roller Shutters.jpg'
import GB from '../Assets/Images/MI - Gate Barriers.jpg'
import SLG from '../Assets/Images/MI- Sliding Gates.jpg'
import SWG from '../Assets/Images/MI - Swing Gates.jpg'
import PF from '../Assets/Images/MI - Palisade Fencing.jpg'
import SOGD from '../Assets/Images/MI - Sectional Overhead Garage Doors.jpg'
import SOID from '../Assets/Images/MI - Sectional Ovehead Industrial Doors.jpg'
import IHSD from '../Assets/Images/MI - Internal High Speed Doors.jpg'
import EHSD from '../Assets/Images/MI - External High Speed Doors.jpg'
import FC from '../Assets/Images/MI - Fire Curtains.jpg'
import SSD from '../Assets/Images/MI - Steel Swing Doors.jpg'
import FRSD from '../Assets/Images/MI - Fire Rated Steel Swing Doors.jpg'
import WID from '../Assets/Images/MI - Wooden Interior Doors.jpg'
import SLD from '../Assets/Images/MI - Sliding Doors.jpg'
import DL from '../Assets/Images/MI - Dock Levelers.jpg'
import FDS from '../Assets/Images/MI - Fixed Dock Shelters.jpg'
import IDS from '../Assets/Images/MI - Inflatable Dock Shelters.jpg'
import RDS from '../Assets/Images/MI - Retractable Dock Shelters.jpg'
import PMS from '../Assets/Images/MI - Parking Management System.jpg'
import PGS from '../Assets/Images/MI - Parking Guidance System.jpg'
import SL from '../Assets/Images/MI - Scissor Lifts.jpg'
import FHTR from '../Assets/Images/MI - Full Height Turnstile.jpg'
import OPTR from '../Assets/Images/MI - Optical Turnstile.jpg'
import STG from '../Assets/Images/MI - Swing Turnstile Gates.jpg'
import TPD from '../Assets/Images/MI - Tripod.jpg'
import FXBD from '../Assets/Images/MI - Fixed Bollards.jpg'
import HYBD from '../Assets/Images/MI - Hydraulica Bollards.jpg'
import RMBD from '../Assets/Images/MI - Removable Bollards.jpg'

import CARS from '../Assets/Images/CI - Aluminium Roller Shutters.jpg'
import CGRS from '../Assets/Images/CI - GI Roller Shutters.jpg'
import CPRS from '../Assets/Images/CI - Polycarbonate Rolling Shutters.jpg'
import CFRS from '../Assets/Images/CI - Fire Rated Roller Shutters.jpg'
import CGB from '../Assets/Images/CI - Gate Barriers.jpg'
import CSLG from '../Assets/Images/CI - Sliding Gates.jpg'
import CSWG from '../Assets/Images/CI - Swing Gates.jpg'
import CPF from '../Assets/Images/CI - Palisade Fencing.jpg'
import CSGD from '../Assets/Images/CI - Sectional Overhead Garage Door.jpg'
import CSID from '../Assets/Images/CI -  Sectional Overhead Industrial Door.jpg'
import CIHD from '../Assets/Images/CI - Internal High Speed PVC Door.jpg'
import CEHD from '../Assets/Images/CI - External High Speed Door.jpg'
import CFC from '../Assets/Images/CI - Fire Curtain.jpg'
import CSSD from '../Assets/Images/CI - Steel Swing Doors.jpg'
import CFSD from '../Assets/Images/CI - Fire Rated Steel Swing Doors.jpg'
import CWID from '../Assets/Images/CI - Wooden Interior Doors.jpg'
import CSD from '../Assets/Images/CI - Sliding Doors.jpg'
import CDS from '../Assets/Images/CI - Dock Shelters.jpg'
import CDL from '../Assets/Images/CI - Dock Levelers.jpg'
import CPMS from '../Assets/Images/CI - Parking Management System.jpg'
import CPGS from '../Assets/Images/CI - Parking Guidance System.jpg'
import CSL from '../Assets/Images/CI - Scissor Lifts.jpg'
import CBS from '../Assets/Images/CI - Bollards.jpg'
import CTS from '../Assets/Images/CI - Turnstiles.jpg'

 


const products = [
    {
        id: 1,
        category: 'Roller Shutters', 
        subCategory: [
            {
                id: 11,
                name: 'Aluminum Roller Shutters',
                route: '/products/aluminum-shutters',
                cardImg: CARS,
                cardDescription: "Secure Elegance in Motion. Discover the perfect blend of strength and sophistication with our Aluminum Roller Shutters. Crafted to safeguard your property with modern style, these shutters offer unbeatable security and aesthetics.",
                paragraph1: "Aluminum roller shutters are a versatile and durable choice for securing commercial and industrial spaces. These shutters are designed to provide exceptional security while offering a modern and sleek appearance. The curtain slats, measuring 75mm with a thickness of 1 to 2 mm, ensure robust protection against intruders and harsh weather conditions. The minimum 5-inch diameter barrel, guide rails in 'G' type with 3mm thickness, and an inverted 'T' type GI/MS bottom bar of minimum 3mm thickness further enhance security. Powered by a 1-phase or 3-phase motor, these shutters operate efficiently and reliably. They are finished with a powder coat in a standard RAL color, providing an attractive and long-lasting appearance.",
                paragraph2: "Aluminum roller shutters offer various optional features to enhance functionality and safety. Options include safety photocells, wind locks for added stability, and a bottom seal for improved insulation and weather protection. These shutters can be customized to fit openings up to 12 meters wide and 8 meters in height, and larger sizes are available upon request. Whether it's for a storefront, warehouse, or industrial facility, aluminum roller shutters provide the ideal combination of security, durability, and aesthetics. They are a reliable solution for safeguarding your property and assets while maintaining a professional and modern look.",
                subCategory: [
                    {
                        id: 111,
                        name: "Aluminum Insulated Roller Shutters",
                        img: AIS,
                        paragraph1: "Aluminum insulated roller shutters stand out as a top-tier choice for security and energy efficiency, featuring a unique polyurethane insulation core and the distinction of being European-made. The polyurethane insulation, sandwiched between robust aluminum slats, provides exceptional thermal regulation, effectively preventing heat loss during winters and keeping interiors cool in summer, thus leading to reduced energy consumption and lower utility bills. Furthermore, being European-made, these shutters embody a commitment to quality and craftsmanship, adhering to stringent manufacturing standards. Their precise engineering ensures a smooth and reliable operation, guaranteeing longevity and peace of mind. Together, these attributes make aluminum insulated roller shutters an outstanding investment for homes and businesses alike.",
                        features: [
                            {
                              feature: "Superior Thermal Insulation",
                              description: "Equipped with a polyurethane insulation core for excellent thermal performance, reducing energy consumption and maintaining consistent interior temperatures.",
                            },
                            {
                              feature: "Enhanced Security",
                              description: "Durable aluminum construction and a secure locking system provide robust security, deterring intruders and protecting against break-ins.",
                            },
                            {
                              feature: "European Quality",
                              description: "Manufactured in Europe with rigorous standards, ensuring precision, reliability, and longevity. Crafted with attention to detail for a smooth and efficient operation over time.",
                            }
                        ]
                    },
                    {
                        id: 112,
                        name: "Aluminum Single Skin Roller Shutter",
                        img: ALSS,
                        paragraph1: "Aluminum Single Skin Roller Shutters offer a simple yet effective solution for security and versatility. First, their lightweight and cost-effective design makes them an accessible choice for various applications, from residential garages to commercial storefronts. Second, these shutters provide a robust physical barrier, enhancing security and privacy while withstanding environmental elements. Finally, their low-maintenance nature ensures ease of use, making them a practical choice for those seeking hassle-free operation. Whether safeguarding your property or adding a layer of privacy, Aluminum Single Skin Roller Shutters are a reliable and budget-friendly choice.",
                        features: [
                            {
                              feature: "Cost-Effective Design",
                              description: "Lightweight and budget-friendly, making them an accessible choice for various applications."
                            },
                            {
                              feature: "Enhanced Security",
                              description: "Provides a robust physical barrier, enhancing security and privacy while withstanding environmental elements."
                            },
                            {
                              feature: "Low-Maintenance",
                              description: "Requires minimal upkeep, ensuring ease of use and making them a practical choice for hassle-free operation."
                            }
                        ]
                      }  
                ],
                technicals: [
                    { name: "Curtain Slat", value: "55mm | 77mm | 100mm pitch  with 1 to 1.5 mm skin thickness" },
                    { name: "Barrel", value: "Minimum 5 inch dia min, 4mm thick" },
                    { name: "Guide Rails", value: "‘C’  Type, 3mm Thick" },
                    { name: "Bottom Bar", value: "Inverted ‘T’ type GI/MS Minimum 3mm thick" },
                    { name: "Motor", value: "1 phase, 230 V, 50HZ,13A | 3 phase, 415V, 50Hz, 20A" },
                    { name: "Finish", value: "Powder coated to a standard RAL finish" },
                    { name: "Optional", value: "Safety Photocell ; Wind lock ; Bottom Seal ; Remote Control" },
                    { name: "Max Size", value: "Up to 12 Meter wide x 8 Meter height. Higher sizes are available on request" }
                ],     
            },
            {
                id: 12,
                name: "GI Roller Shutters",
                route: '/products/galvanised-shutters',
                cardImg: CGRS,
                cardDescription: "Robust Protection, Trusted Security. Our GI Roller Shutters are engineered to provide dependable security for your premises. Made from high-quality galvanized iron, they offer durability and peace of mind.",
                paragraph1: "GI roller shutters, made from galvanized iron, are known for their durability and strength. These shutters are designed to offer robust security for both commercial and industrial spaces. With a solid curtain slat design and sturdy construction, GI roller shutters provide reliable protection against intruders and adverse weather conditions.",
                paragraph2: "GI roller shutters can be customized to fit a wide range of openings, making them a versatile choice for various applications. Their simple yet effective design ensures smooth operation, and they can be equipped with additional features such as safety photocells and wind locks for enhanced security and functionality.",
                subCategory: [
                    {
                        id: 121,
                        name: "GI Single Skin Roller Shutters",
                        img: GISS,
                        paragraph1: "Galvanized Iron (GI) Single Skin Roller Shutters are a versatile choice known for their strength and utility. First, their galvanized iron construction ensures exceptional durability and resistance to corrosion, making them ideal for outdoor use. Second, these shutters offer reliable security, effectively safeguarding premises against unauthorized access while allowing for easy customization to meet specific security requirements. Lastly, they are well-suited for various commercial and industrial settings due to their adaptability, providing a cost-effective solution for businesses seeking both protection and flexibility in their access control needs. GI Single Skin Roller Shutters combine robustness and practicality, making them a valuable addition to any property.",
                        features: [
                            {
                              feature: "Exceptional Durability",
                              description: "Constructed from galvanized iron for robustness and corrosion resistance, ensuring a long-lasting security solution.",
                            },
                            {
                              feature: "Customizable Security",
                              description: "Designed to allow customization to meet specific security requirements, making them suitable for a variety of applications.",
                            },
                            {
                              feature: "Versatile Industrial Use",
                              description: "Ideal for commercial and industrial settings due to their strength and adaptability, providing cost-effective protection and flexibility."
                            }
                        ]
                    },
                    {
                        id: 122,
                        name: "GI Insulated Roller Shutter",
                        img: GIIS,
                        paragraph1: "Galvanized Iron (GI) Insulated Roller Shutters with a choice of either Polyurethane or Rockwool insulation offer exceptional features for insulation and durability. These shutters are constructed with galvanized iron, ensuring robustness and resistance to corrosion, making them suitable for diverse environments. The key feature is the insulation options available: with Polyurethane, they provide excellent thermal efficiency, maintaining consistent interior temperatures and reducing energy consumption. Alternatively, with Rockwool insulation, they offer enhanced fire resistance and sound insulation, ideal for industrial or fire-rated applications. This flexibility in insulation options, combined with the strength of galvanized iron, ensures that GI Insulated Roller Shutters are a versatile and reliable solution for various commercial and industrial settings.",
                        features: [
                            {
                              feature: "Rugged Durability",
                              description: "Constructed from galvanized iron for exceptional strength and corrosion resistance, ensuring a long-lasting and sturdy security solution.",
                            },
                            {
                              feature: "Superior Thermal Insulation",
                              description: "Equipped with high-quality insulation (e.g., Polyurethane or Rockwool) for excellent thermal performance, maintaining consistent interior temperatures and reducing energy consumption.",
                            },
                            {
                              feature: "Versatile Application",
                              description: "Suitable for a wide range of commercial and industrial settings, offering security, thermal efficiency, and adaptability for various needs."
                            }
                        ]
                    },
                    {
                        id: 123,
                        name: "GI Perforated Roller Shutter",
                        img: GIPRS,
                        paragraph1: "GI Perforated roller shutters are a highly versatile solution that strikes an ideal balance between security and visibility. These shutters are characterized by their clever design, featuring a multitude of small holes or slots that not only deter unauthorized access but also allow natural light to filter through, creating a welcoming and well-lit atmosphere for commercial spaces. This transparency also serves as a unique storefront showcase, permitting customers to view products and displays even when the business is closed, ultimately boosting engagement and enhancing curb appeal. Additionally, these shutters provide an effective shield against vandalism and theft while enabling airflow, promoting a comfortable indoor environment. In essence, perforated roller shutters offer a smart and stylish way to secure and showcase your business simultaneously.",
                        features: [
                            {
                              feature: "Enhanced Visibility and Display",
                              description: "The perforated design allows natural light, limited visibility, and a showcase of products even when the business is closed, enhancing customer engagement and curb appeal."
                            },
                            {
                              feature: "Security and Protection",
                              description: "Despite the perforations, these shutters provide a secure barrier against unauthorized access and protect against vandalism and theft, with perforations designed for security."
                            },
                            {
                              feature: "Natural Light and Ventilation",
                              description: "The perforated structure permits natural light to enter, reducing the need for artificial lighting during the day, and also allows airflow for a more comfortable indoor environment."
                            }
                        ]
                    }
                      
                ],
                technicals: [
                    { name: "Curtain Slat", value: "75mm | 115mm pitch  with 1 to 1.5 mm skin thickness" },
                    { name: "Barrel", value: "Minimum 5 inch dia min, 4mm thick" },
                    { name: "Guide Rails", value: "‘C’ Type, 3mm Thick" },
                    { name: "Bottom Bar", value: "Inverted ‘T’ type GI/MS Minimum 3mm thick" },
                    { name: "Motor", value: "1 phase, 230 V, 50HZ,13A | 3 phase, 415V, 50Hz, 20A" },
                    { name: "Finish", value: "Powder coated to a standard RAL finish" },
                    { name: "Optional", value: "Safety Photocell ; Wind lock ; Bottom Seal ; Remote Control" },
                    { name: "Max Size", value: "Up to 12 Meter wide x 8 Meter height. Higher sizes are available on request" }
                ]
              },
              {
                id: 13,
                name: "Polycarbonate Roller Shutters",
                route: '/products/polycarbonate-shutters',
                cardImg: CPRS,
                img: PCRS,
                cardDescription: "Translucent Defense, Natural Light. Embrace the security of our Polycarbonate Roller Shutters while enjoying the benefits of natural light. These shutters offer transparency, strength, and privacy all in one package.",
                paragraph1: "Polycarbonate roller shutters are a versatile and modern solution for commercial and industrial properties seeking an effective balance between security and aesthetics. These shutters are constructed with polycarbonate panels that are not only strong and durable but also transparent, allowing natural light to filter through even when the shutters are closed. Their contemporary design makes them a visually appealing choice for businesses looking to showcase their storefronts while maintaining security. Polycarbonate roller shutters are suitable for various applications, including storefronts, shopping malls, and display windows, where they offer an enticing blend of visibility and protection.",
                paragraph2: "In addition to their attractive appearance, polycarbonate roller shutters provide robust security features. Their transparency allows for 24/7 product visibility, making them an excellent choice for businesses that want to display their merchandise, even when the premises are closed. These shutters are often equipped with motorized systems for ease of operation, and they can be customized with various locking mechanisms to suit specific security requirements. Polycarbonate roller shutters are not only a stylish choice but also a practical one, as they enhance visibility and curb appeal while ensuring the safety and security of the property.",
                features: [
                    {
                      feature: "Exceptional Transparency",
                      description: "Constructed with transparent polycarbonate panels that allow maximum visibility, making them ideal for showcasing products and enhancing natural light."
                    },
                    {
                      feature: "Strong and Impact-Resistant",
                      description: "Polycarbonate panels are highly durable and impact-resistant, providing security against forced entry and protection from harsh weather conditions."
                    },
                    {
                      feature: "Energy-Efficient Design",
                      description: "The insulating properties of polycarbonate help maintain consistent indoor temperatures, reducing energy consumption and offering cost savings."
                    }
                ],
                technicals: [
                    { name: "Curtain Slat", value: "Transparent Slat with Aluminum Connecting Rods" },
                    { name: "Barrel", value: "Minimum 5inch dia min, 4mm thick" },
                    { name: "Guide Rails", value: "‘C’ Type, 3mm Thick" },
                    { name: "Bottom Bar", value: "Rubbered RMTR" },
                    { name: "Motor", value: "1 phase, 230 V, 50HZ,13A | 3 phase, 415V, 50Hz, 20A" },
                    { name: "Finish", value: "Polycarbonate see through" },
                    { name: "Optional", value: "Safety Photocell ; Wind lock ; Bottom Seal ; Remote Control" },
                    { name: "Max Size", value: "Up to 8 Meter wide x 6 Meter height. Higher sizes are available on request" }
                ]
              },
              {
                id: 14,
                name: "Fire Rated Roller Shutters",
                route: '/products/fire-rated-shutters',
                cardImg: CFRS,
                cardDescription: "Safety Under Fire. Protect your property from fire hazards with our Fire Rated Roller Shutters. Engineered to withstand high temperatures, they are your first line of defense when it matters most.",
                paragraph1: "Fire rated roller shutters are essential for fire safety in commercial and industrial settings. These shutters are designed to compartmentalize areas in the event of a fire, preventing the spread of flames and smoke. They are constructed with fire-resistant materials to withstand high temperatures.",
                paragraph2: "In addition to their fire protection capabilities, fire rated roller shutters can also provide security and insulation benefits. They are a crucial component of fire safety plans for buildings and are available in various fire resistance ratings to suit different applications.",
                subCategory: [
                    {
                        id: 141,
                        name: "GI Fire Rated Insulated Roller Shutters",
                        img: FRIS,
                        paragraph1: "GI (Galvanized Iron) Fire Rated Insulated Roller Shutters, boasting a remarkable 120-minute fire rating and certified by the Abu Dhabi Civil Authority, stand as a pinnacle in fire safety and security solutions. Crafted from galvanized iron, they offer unparalleled durability and resistance to external factors, while their two-hour fire rating ensures prolonged fire resistance, providing ample time for evacuation and containment. This certification from the Abu Dhabi Civil Authority underlines their compliance with rigorous safety standards. Combining robust construction, extended fire protection, and official certification, these roller shutters are the ultimate choice for safeguarding lives and property in high-risk fire-prone environments.",
                        features: [
                            {
                              feature: "Extended Fire Resistance",
                              description: "Boasting a remarkable 120-minute fire rating, these shutters are designed to withstand and contain fires, providing ample time for evacuation and fire containment.",
                            },
                            {
                              feature: "Abu Dhabi Civil Authority Certified",
                              description: "Certified by the Abu Dhabi Civil Authority, these shutters adhere to rigorous safety standards and are officially recognized for their compliance with fire safety regulations.",
                            },
                            {
                              feature: "Rockwool Insulation",
                              description: "Enhanced with Rockwool insulation, these shutters offer exceptional fire resistance by withstanding high temperatures and minimizing the spread of flames and smoke."
                            }
                        ]
                    },
                    {
                        id: 142,
                        name: "GI Fire Rated Single Skin Roller Shutter",
                        img: FRSS,
                        paragraph1: "Galvanized Iron (GI) Fire Rated Single Skin Roller Shutters, with a remarkable 120-minute fire rating, are a crucial safety solution for environments where fire protection is paramount. Crafted from galvanized iron, they offer robust security and durability, capable of withstanding harsh conditions and physical impact. Their 120-minute fire rating ensures an extended period of fire resistance, providing valuable time for safe evacuation and fire containment. Whether used in industrial settings, commercial properties, or sensitive areas, these roller shutters, with their rigorous fire safety standards and a two-hour fire rating, make a significant contribution to the overall safety and security of the premises.",
                        features: [
                            {
                              feature: "120-Minute Fire Rating",
                              description: "These roller shutters offer a remarkable 120-minute fire rating, providing an extended period of fire resistance for enhanced safety and protection."
                            },
                            {
                              feature: "Robust Galvanized Iron Construction",
                              description: "Crafted from galvanized iron, these shutters are known for their exceptional durability and resistance to harsh conditions and physical impact."
                            },
                            {
                              feature: "Fire Containment",
                              description: "Designed to effectively contain and isolate fires, these shutters play a crucial role in protecting lives and property during fire emergencies."
                            },

                            {
                              feature: "Civil Defense",
                              description: "Complies with the norms of Dubai and Abu Dhabi Civil Defense."
                            }
                        ]
                      }

                ],
                technicals: [
                    { name: "Curtain Slat", value: "55mm | 77mm | 100mm pitch  with 1 to 2 mm skin thickness" },
                    { name: "Barrel", value: "Minimum 5inch dia min, 4mm thick" },
                    { name: "Guide Rails", value: "‘C’ Type, 3mm Thick" },
                    { name: "Bottom Bar", value: "Inverted ‘T’ type GI/MS Minimum 3mm thick" },
                    { name: "Motor", value: "1 phase, 230 V, 50HZ,13A | 3 phase, 415V, 50Hz, 20A" },
                    { name: "Finish", value: "Powder coated to a standard RAL finish" },
                    { name: "Optional", value: "Safety Photocell ; Wind lock ; Bottom Seal ; Remote Control" },
                    { name: "Max Size", value: "Up to 12 Meter wide x 8 Meter height. Higher sizes are available on request" }
                ]
              }
        ]
    }, 
    {
        id: 2,
        category: 'Gates and Fences', 
        subCategory: [
            {
                id: 21,
                name: "Gate Barriers",
                route: '/products/gate-barriers',
                cardImg: CGB,
                cardDescription: "Controlled Access, Enhanced Security. Our Gate Barriers provide controlled entry and exit, enhancing the security of your premises. They are designed for smooth operation and robust protection.",
                img: GB,
                paragraph1: "Parking gate barriers serve as the first line of defense in regulating vehicle access and enhancing security in parking facilities. These robust and automated systems effectively control the entry and exit of vehicles, ensuring that only authorized individuals gain access. With their sturdy construction and reliable mechanisms, parking gate barriers offer a physical deterrent against unauthorized vehicles, contributing to the overall safety of the premises. Whether you're managing a commercial parking lot, a residential community, or an industrial site, these barriers provide peace of mind by preventing unauthorized entry and regulating traffic flow.",
                paragraph2: "In addition to security, parking gate barriers offer convenience and efficiency. With features like remote control and access management systems, they enable smooth and seamless entry and exit for authorized users, reducing congestion and wait times. Some systems also incorporate safety sensors and warning lights, further enhancing the overall safety of the parking area. Parking gate barriers are an integral part of modern parking management, combining security, convenience, and efficiency to create a controlled and hassle-free experience for both operators and users.",
                features: [
                    {
                      feature: "Remote Control and Access Management",
                      description: "Gate barriers are equipped with remote control systems and access management technology, allowing authorized users to operate and manage the gate efficiently."
                    },
                    {
                      feature: "Robust Security and Access Control",
                      description: "Gate barriers provide a formidable security barrier, controlling vehicle access to premises and enhancing overall safety and security."
                    },
                    {
                      feature: "Durable and Weather-Resistant",
                      description: "Designed to withstand the elements, gate barriers are built to be durable and weather-resistant, ensuring long-lasting performance and minimal maintenance."
                    }
                ],
                technicals: [
                    { name: "Barrier Arm Material", value: "Aluminum | Steel" },
                    { name: "Motor Type", value: "1 phase, 230 V, 50Hz, 0.75kW | 3 phase, 415V, 50Hz, 1.5kW" },
                    { name: "Operating Speed", value: "4-6 seconds | 6-8 seconds" },
                    { name: "Boom Lighting", value: "LED Lights | No Lighting" },
                    { name: "Control System", value: "Remote Control | RFID/ Proximity Access Control | Push Button" },
                    { name: "Finish", value: "Powder coated to company standard RAL finish" },
                    { name: "Optional", value: " Vehicle Loop Detector ; Safety Photocell ; Battery Backup ; Manual Release" },
                    { name: "Max Boom Length", value: "Up to 6 meters | Longer lengths available on request" }
                ]
            },
            {
                id: 22,
                name: "Sliding Gates",
                route: '/products/sliding-gates',
                cardImg: CSLG,
                cardDescription: "Effortless Entry, Maximum Security. Experience convenience and security with our Sliding Gates. These gates are designed for easy operation and provide a strong barrier against unauthorized access.",
                img: SLG,
                paragraph1: "Sliding gates are a powerful fusion of security and elegance, offering an effective means of controlling access while enhancing the visual appeal of properties. These gates slide horizontally to open or close, making them an excellent choice when space is limited or aesthetics are a priority. Crafted from durable materials like steel or wrought iron, sliding gates are not only formidable barriers but also enduring elements that stand the test of time and environmental factors. Their sleek and modern design suits a wide range of settings, from residential driveways and estates to commercial complexes and industrial facilities.",
                paragraph2: "What makes sliding gates particularly appealing is their convenience and adaptability. With options for automation and remote control, they provide a seamless entry and exit experience, eliminating the need for manual operation. These gates can also be customized to match the architectural style of the property, creating a harmonious blend of security and aesthetics. Whether you seek to bolster the security of your property or add a touch of sophistication to its entrance, sliding gates are a versatile and stylish solution that promises both functionality and visual appeal.",
                features: [
                    {
                      feature: "Space-Efficient Design",
                      description: "Sliding gates are ideal for properties with limited space as they operate horizontally, requiring minimal clearance and making them a space-efficient choice."
                    },
                    {
                      feature: "Smooth and Silent Operation",
                      description: "These gates are known for their smooth and virtually silent operation, providing convenience and reducing noise disturbance in residential and commercial areas."
                    },
                    {
                      feature: "Customization and Security",
                      description: "Sliding gates offer a range of customization options, including materials, designs, and security features, making them a versatile solution for both aesthetic appeal and security needs."
                    }
                ],

                technicals: [
                    { name: "Gate Type", value: "Cantilever | Tracked " },
                    { name: "Gate Material", value: "Steel | Aluminum" },
                    { name: "Gate Height", value: "Up to 4 meters |  Larger widths available on request" },
                    { name: "Automation", value: "Manual | Motorized" },
                    { name: "Motor Power", value: "1 phase, 230 V, 50Hz, 0.75kW | 3 phase, 415V, 50Hz, 1.5kW" },
                    { name: "Control System", value: "Remote Control | Keypad Entry" },
                    { name: "Finish", value: "Powder coated to a standard RAL finish" },
                    { name: "Optional", value: "Safety Photocell ; Vehicle Loop Detector ; Battery Backup ; Intercom System" },
                    { name: "Max Gate Width", value: "Up to 15 meters | Longer widths available on request" }
                ]
            },
            {
                id: 23,
                name: "Swing Gates",
                route: '/products/swing-gates',
                cardImg: CSWG,
                cardDescription: "Timeless Elegance, Reliable Security. Our Automatic and Manual Swing Gates offer a classic touch to your property while ensuring reliable security. Crafted with precision, they are a statement of durability and style.",
                img: SWG,
                paragraph1: "Swing gates offer a timeless and elegant solution for controlling access to a wide variety of properties, from homes and estates to commercial buildings and gated communities. Their classic design, characterized by two hinged panels that swing open and closed, imparts a touch of sophistication while providing reliable security. Swing gates are often crafted from durable materials like wrought iron or aluminum, ensuring both longevity and an impressive appearance. With their versatility in design and customization, these gates can be tailored to complement the aesthetic of any property, whether you prefer a traditional, ornate style or a more modern and streamlined look.s",
                paragraph2: "One of the primary advantages of swing gates is their convenience and ease of use. They can be operated manually or equipped with automated systems for remote control, making entry and exit effortless. Additionally, swing gates require less maintenance compared to some other gate types, contributing to their overall cost-effectiveness. Whether you prioritize security, aesthetics, or a combination of both, swing gates offer a charming and functional solution for enhancing the access control and visual appeal of your property.",
                features: [
                    {
                      feature: "Remote Control and Access Management",
                      description: "Gate barriers are equipped with remote control systems and access management technology, allowing authorized users to operate and manage the gate efficiently."
                    },
                    {
                      feature: "Robust Security and Access Control",
                      description: "Gate barriers provide a formidable security barrier, controlling vehicle access to premises and enhancing overall safety and security."
                    },
                    {
                      feature: "Durable and Weather-Resistant",
                      description: "Designed to withstand the elements, gate barriers are built to be durable and weather-resistant, ensuring long-lasting performance and minimal maintenance."
                    }
                ],
                technicals: [
                    { name: "Gate Material", value: "Steel | Wrought Iron" },
                    { name: "Gate Height", value: "1.2 meters | 1.5 meters | 1.8 meters" },
                    { name: "Automation", value: "Manual | Motorized" },
                    { name: "Motor Power", value: "1 phase, 230 V, 50Hz, 0.75kW | 3 phase, 415V, 50Hz, 1.5kW" },
                    { name: "Control System", value: "Remote Control | Keypad Entry" },
                    { name: "Finish", value: "Powder coated to a standard RAL finish" },
                    { name: "Optional", value: "Safety Photocell | Intercom System | Keyless Entry | Lighting" },
                    { name: "Max Gate Width", value: "Up to 5 meters | Larger widths available on request" }
                ]
            }
              ,
            {
                id: 24,
                name: "Palisade Fencing",
                route: '/products/palisade-fencing',
                cardImg: CPF,
                cardDescription: "Protective Perimeter, Uncompromising Defense. Our Palisade Fencing is the perfect choice for safeguarding your property. Its formidable design and durability create a strong barrier against intruders.",
                img: PF,
                paragraph1: "Palisade fencing stands as a symbol of security and resilience, providing a robust barrier that deters unauthorized access and protects properties. The distinctive design of palisade fencing, characterized by its vertical pales or pickets, offers both an imposing visual deterrent and a formidable physical barrier. Crafted from high-quality materials like steel, this fencing system is built to withstand the test of time and the harshest of environmental conditions. It's a go-to choice for securing commercial and industrial sites, critical infrastructure, and even residential properties, as it's effective at preventing intrusion and trespassing.",
                paragraph2: "Beyond its imposing appearance, palisade fencing offers a range of advantages. Its open design ensures minimal wind resistance, making it suitable for high-wind areas, and its height can be customized for specific security needs. The fence's design also provides excellent visibility, which is particularly valuable for surveillance and monitoring purposes. Palisade fencing is a versatile and durable security solution that combines functionality with an imposing appearance, making it an ideal choice for safeguarding a wide range of properties.",
                features: [
                    {
                        feature: "High Security",
                        description: "Palisade fencing is designed with sharp-topped vertical steel or aluminum pales that act as a formidable deterrent to intruders, enhancing security for properties."
                    },
                    {
                        feature: "Durable and Low Maintenance",
                        description: "These fences are known for their durability, as they are often made of galvanized or powder-coated materials that are resistant to weather and require minimal maintenance."
                    },
                    {
                        feature: "Aesthetic Versatility",
                        description: "Palisade fencing comes in various designs and finishes, allowing for aesthetic customization to match the property's style while maintaining security and privacy."
                    }
                ],
                technicals: [
                    { name: "Fence Height", value: "1.8 meters | 2 meters | 2.4 meters" },
                    { name: "Fence Material", value: "Steel | Galvanized Steel" },
                    { name: "Fence Design", value: "D Section | W Section" },
                    { name: "Finish", value: "Powder coated to a standard RAL finish" },
                    { name: "Optional", value: "Security Toppings | Anti-Climb Features | Sliding Gates | Automation" },
                ]
            }    
        ]

    }, 
    {
        id: 3, 
        category: 'Sectional Overhead Doors',
        subCategory: [
            {
                id: 31,
                name: "Sectional Overhead Residential Garage Door",
                route: '/products/garage-sectional-doors',
                cardImg: CSGD,
                cardDescription: "Effortless Garage Access. Experience effortless garage access with our Sectional Overhead Garage Door. It's designed for durability and smooth operation.",
                img: SOGD,
                paragraph1: "Sectional overhead garage doors are the trusted guardians of residential garages, offering both security and convenience to homeowners. These doors are designed with a series of hinged panels that roll vertically along tracks when opened, maximizing space and allowing vehicles to park close to the door. Constructed from sturdy materials like steel or aluminum, sectional overhead garage doors provide reliable protection against intruders and harsh weather conditions. Additionally, insulation options are available to maintain a comfortable and energy-efficient environment within the garage, whether it's used for parking, storage, or as a workspace.",
                paragraph2: "The appeal of sectional overhead garage doors extends beyond their functionality. They come in a wide range of styles, colors, and finishes, allowing homeowners to customize the door to match their home's exterior design. With the addition of automated systems, these doors can be effortlessly opened and closed with a remote control, offering an extra layer of convenience. Sectional overhead garage doors are a testament to the seamless integration of security, aesthetics, and practicality, making them a staple for homeowners looking to enhance the value and utility of their properties.",
                features: [
                    {
                      feature: "Space-Efficient Design",
                      description: "Sectional garage doors open vertically, maximizing overhead space in your garage, making them ideal for properties with limited driveway space."
                    },
                    {
                      feature: "Insulation and Energy Efficiency",
                      description: "Many sectional doors come with insulation options that help regulate temperature, reducing energy consumption and making them suitable for both residential and commercial spaces."
                    },
                    {
                      feature: "Durable and Secure",
                      description: "Constructed from high-quality materials, these doors are known for their durability, weather resistance, and strong security features, ensuring the protection of your belongings and property."
                    }
                  ],
                technicals: [
                    { name: "Door Type", value: "Sectional Overhead" },
                    { name: "Material", value: "Steel | Aluminum | Wood" },
                    { name: "Insulation", value: "Non-Insulated | Single-Layer Insulation | Double-Layer Insulation | Triple-Layer Insulation" },
                    { name: "Design Style", value: "Raised Panel | Carriage House | Contemporary" },
                    { name: "Color Options", value: "White | Almond | Sandstone | Custom Colors Available" },
                    { name: "Windows", value: "With Windows | Without Windows" },
                    { name: "Window Design", value: "Rectangular | Arched | Decorative Inserts" },
                    { name: "Hardware", value: "Standard Hardware | Decorative Hardware" },
                    { name: "Motor Type", value: "Belt Drive | Chain Drive | Screw Drive" },
                    { name: "Control System", value: "Remote Control | Smartphone Control | Keypad Entry" },
                    { name: "Security Features", value: "Rolling Code Technology | Motion Sensors | Auto-Lock" },
                    { name: "Opener Power", value: "1/2 HP | 3/4 HP | 1 HP" },
                    { name: "Warranty", value: "Limited Lifetime Warranty" },
                    { name: "Optional Accessories", value: "Battery Backup | WiFi Connectivity | Keyless Entry" },
                ]
            }, 
            {
                id: 32,
                name: "Industrial Sectional Overhead Door",
                route: '/products/industrial-sectional-doors',
                cardImg: CSID,
                cardDescription: "Industrial Strength, Dependable Access. Our Sectional Overhead Industrial Door provides robust access control for industrial spaces. It's built to handle the toughest environments.",
                img: SOID,
                paragraph1: "Sectional overhead industrial doors are the workhorses of warehouses, logistics centers, and industrial facilities, offering a blend of rugged durability and secure access control. These doors are engineered for heavy-duty applications, designed to endure the rigors of industrial environments where frequent operation and robust security are paramount. Constructed from tough materials like steel, sectional overhead industrial doors are built to withstand the test of time and provide an impenetrable barrier against unauthorized access and the elements. They often come with insulation options, ensuring temperature regulation and energy efficiency within the facility, an essential feature for maintaining optimal working conditions.",
                paragraph2: "What makes sectional overhead industrial doors indispensable in industrial settings is their efficiency. With automated systems, these doors allow for quick and convenient entry and exit of vehicles and goods, which is vital for streamlining logistics and maintaining productivity. Their customizable design and integration capabilities enable them to seamlessly blend with existing infrastructure and building management systems. Whether you prioritize security, operational efficiency, or a combination of both, sectional overhead industrial doors stand as stalwart sentinels, guarding the assets and operations of industrial facilities with unwavering strength and dependability.",
                features: [
                    {
                      feature: "Heavy-Duty Construction",
                      description: "Sectional overhead industrial doors are built with robust materials, making them capable of withstanding heavy usage and providing long-lasting reliability."
                    },
                    {
                      feature: "Quick and Secure Access",
                      description: "These doors offer rapid opening and closing, ensuring efficient access for large vehicles and equipment while maintaining security through advanced locking and access control systems."
                    },
                    {
                      feature: "Customization for Industrial Needs",
                      description: "Designed to suit industrial environments, these doors can be customized with options like insulation, high wind resistance, and safety features to meet specific operational and safety requirements."
                    }
                ],
                technicals: [
                    { name: "Door Material", value: "Steel | Aluminum | Insulated Panels" },
                    { name: "Operation", value: "Manual | Motorized" },
                    { name: "Size Options", value: "Various sizes available" },
                    { name: "Security Features", value: "Heavy-Duty Locking Mechanisms | Safety Sensors" },
                    { name: "Additional Options", value: "High-Cycle Design | Custom Colors | Windows for Visibility" },
                ]
            }
              
        ]
    }, 
    {
        id: 4, 
        category: 'High Speed Doors and Fire Curtains',
        subCategory: [
            {
                id: 41,
                name: "Internal High-Speed PVC",
                route: '/products/internal-high-speed-doors',
                cardImg: CIHD,
                cardDescription: "Efficiency Within Reach. Our Internal High-Speed Door is engineered for swift, secure access within your facility. Enhance workflow efficiency with a secure environment.",
                img: IHSD,
                paragraph1: "Internal high-speed PVC doors are a valuable addition to various industrial and commercial settings, where rapid access and environmental control are essential. These doors are engineered for swift operation, opening and closing with remarkable speed to minimize heat loss, control dust, and enhance traffic flow within a facility. Made from high-quality PVC material, these doors are both durable and flexible, allowing for thousands of cycles without wear and tear. Their design offers airtight and watertight sealing, making them an ideal choice for temperature-controlled environments, clean rooms, and areas with heavy foot traffic.",
                paragraph2: "The advantages of internal high-speed PVC doors extend to their safety features and practicality. Equipped with sensors and controls, they ensure the safety of personnel and equipment during operation. Additionally, these doors contribute to energy efficiency by reducing heat loss, ultimately leading to cost savings. In fast-paced industrial settings, these doors offer a seamless solution for maintaining environmental control, enhancing traffic efficiency, and bolstering security. Whether used in food processing plants, warehouses, or healthcare facilities, internal high-speed PVC doors are a reliable and practical choice for managing access and ensuring a controlled interior environment.",
                features: [
                    {
                      feature: "Rapid Opening and Closing",
                      description: "High-speed PVC doors are designed for swift and efficient operation, minimizing air exchange between different areas while allowing fast passage of personnel and equipment."
                    },
                    {
                      feature: "Energy Efficiency",
                      description: "These doors help maintain controlled environments by reducing temperature loss and preventing drafts, contributing to energy savings and environmental sustainability."
                    },
                    {
                      feature: "Safety and Durability",
                      description: "Equipped with safety features like motion sensors and breakaway design, high-speed PVC doors prioritize safety and can endure the rigors of industrial environments, reducing maintenance costs."
                    }
                  ],
                technicals: [
                    { name: "Door Material", value: "High-Speed PVC" },
                    { name: "Operation", value: "Motorized" },
                    { name: "Size Options", value: "Custom Sizes Available" },
                    { name: "Speed", value: "High Opening and Closing Speed" },
                    { name: "Safety Features", value: "Safety Sensors | Emergency Stop | Light Curtain" },
                ]
            },

            {
                id: 42,
                name: "External High-Speed Door",
                route: '/products/external-high-speed-doors',
                cardImg: CEHD,
                cardDescription: "Swift Access, Enhanced Efficiency. Experience rapid entry and exit with our External High-Speed Door. Designed for durability and speed, it improves productivity and security.",
                img: EHSD,
                paragraph1: "External high-speed PVC doors are the ultimate solution for industrial and commercial environments that demand efficiency, durability, and protection from the elements. These doors are designed to provide rapid and reliable access to the outdoors while effectively sealing off indoor spaces from the outside environment. Constructed from robust PVC material, these doors are highly resistant to wear and tear, ensuring long-lasting performance, even in demanding conditions. Their high-speed operation minimizes heat loss, prevents the ingress of dust and contaminants, and enhances traffic flow, making them an excellent choice for loading docks, large industrial openings, and areas with frequent access.",
                paragraph2: "One of the key advantages of external high-speed PVC doors is their ability to withstand challenging weather conditions. They offer superior wind resistance and are capable of enduring extreme temperatures, making them ideal for exterior applications where maintaining a controlled indoor environment is crucial. These doors are equipped with safety features like sensors and controls to ensure the protection of personnel and assets during operation. Their energy-efficient design further contributes to cost savings by reducing energy consumption. In industries such as logistics, manufacturing, and warehousing, external high-speed PVC doors are a dependable solution for optimizing operational efficiency and safeguarding indoor spaces from the outdoor elements.",
                features: [
                    {
                      feature: "Weather Resistance",
                      description: "External high-speed PVC doors are designed to withstand harsh weather conditions, providing a reliable barrier against wind, rain, and temperature extremes."
                    },
                    {
                      feature: "Rapid Operation",
                      description: "These doors offer swift opening and closing, reducing exposure to the external environment, maintaining energy efficiency, and facilitating efficient vehicle and personnel traffic."
                    },
                    {
                      feature: "High Wind Resistance",
                      description: "Engineered to resist high wind loads, these doors remain secure and stable in adverse weather conditions, ensuring the protection of your property."
                    }
                  ],
                technicals:  [
                    { name: "Door Material", value: "High-Speed PVC" },
                    { name: "Operation", value: "Motorized" },
                    { name: "Size Options", value: "Custom Sizes Available" },
                    { name: "Weather Resistance", value: "Designed for External Use, Weatherproof" },
                    { name: "Safety Features", value: "Safety Sensors | Emergency Stop | Wind Resistance System" },
                ]
            }, 
            {
                id: 43,
                name: "Fire Curtains",
                route: '/products/fire-curtains',
                cardImg: CFC,
                cardDescription: "Contain and Protect. Our Fire Curtains are designed to contain and control fire outbreaks, safeguarding lives and property. They are your reliable defense in critical situations.",
                img: FC,
                paragraph1: "Fire curtains are an essential component of fire protection systems in various commercial and industrial settings. These specially engineered curtains are designed to contain and control the spread of fire and smoke, providing crucial time for evacuation and limiting property damage in the event of a fire. They are made from fire-resistant materials and are typically deployed above openings such as doors or windows. When activated by a fire alarm or manual control, fire curtains descend rapidly to form a barrier that effectively blocks the passage of flames and smoke. This proactive measure plays a critical role in preventing the fire from spreading to adjacent areas and can save lives and property.",
                paragraph2: "The versatility and reliability of fire curtains make them a valuable asset in buildings such as theaters, shopping malls, airports, and industrial facilities. These curtains are available in various designs, including vertical and horizontal installations, offering flexibility to meet the specific needs of a given space. Beyond their fire-resistant properties, fire curtains are often equipped with safety features like automatic descent, manual override, and smoke detectors to ensure prompt and effective operation in case of an emergency. The installation of fire curtains is a proactive measure that adds a layer of safety and security to any building, ensuring that in the event of a fire, the impact is minimized, and lives are protected.",
                features: [
                    {
                      feature: "Fire Containment",
                      description: "Fire and smoke curtains are designed to contain and limit the spread of fire and smoke, protecting lives and property by compartmentalizing spaces during a fire emergency."
                    },
                    {
                      feature: "Rapid Deployment",
                      description: "These curtains are engineered for quick and automatic deployment in the event of a fire, minimizing response time and enhancing fire safety."
                    },
                    {
                      feature: "Transparency and Aesthetic Appeal",
                      description: "Many fire and smoke curtains offer transparent or aesthetically pleasing options, allowing for unobstructed views and maintaining the visual appeal of a space when not in use."
                    }
                  ],
                technicals: [
                    { name: "Curtain Material", value: "Fire-Resistant Fabric" },
                    { name: "Fire Rating", value: "1-hour | 2-hour | 3-hour" },
                    { name: "Smoke Sealing", value: "Effective Smoke Containment" },
                    { name: "Operation", value: "Automatic Deployment upon Fire Detection" },
                    { name: "Safety Features", value: "Heat Sensors | Smoke Detectors | Emergency Manual Control" },
                ]
            }
                
            
        ]
    }, 
    {
        id: 5, 
        category: 'Swing and Sliding Doors',
        subCategory: [
            {
                id: 51,
                name: "Steel Swing Door (Single and Double Leaf)",
                route: '/products/steel-swing-doors',
                cardImg: CSSD,
                cardDescription: "Strength Meets Convenience. Our Steel Swing Door combines strength and convenience for secure entry and exit. Crafted with precision, it is built to last.",
                img: SSD,
                paragraph1: "Steel swing doors offer a durable and secure solution for various industrial and commercial settings where access control and reliability are paramount. These robust doors are constructed from high-quality steel, making them resilient to wear and tear and providing long-lasting performance in demanding environments. They feature a swing-open design, characterized by two hinged panels that create a clear passage when open and a secure barrier when closed. Steel swing doors are particularly popular in settings where security and temperature control are critical, such as warehouses, clean rooms, and food processing facilities.",
                paragraph2: "One of the key advantages of steel swing doors is their adaptability. They come in various sizes and configurations to meet the specific requirements of a space, and they are often available with features like vision panels or windows to facilitate visibility. With options for manual or automated operation, they offer both convenience and efficiency in controlling access. Additionally, these doors can be customized to suit aesthetic preferences and can be integrated with access control systems for enhanced security. Steel swing doors provide a robust and reliable solution for maintaining the integrity of controlled environments while ensuring seamless and secure access for personnel and equipment.",
                features: [
                    {
                      feature: "Classic Aesthetics",
                      description: "Swing doors provide a traditional and timeless appearance, enhancing the visual appeal of homes and commercial spaces."
                    },
                    {
                      feature: "Smooth Operation",
                      description: "These doors are designed for smooth and quiet operation, offering ease of use and reducing noise disturbance in indoor environments."
                    },
                    {
                      feature: "Durability and Safety",
                      description: "Constructed with durable materials, swing doors are built to withstand heavy use and can be equipped with safety features like anti-pinch sensors, ensuring long-lasting and secure operation."
                    }
                  ],
                technicals: [
                    { name: "Door Material", value: "Steel" },
                    { name: "Swing Direction", value: "Inward Swing | Outward Swing" },
                    { name: "Door Size", value: "Standard Sizes Available" },
                    { name: "Hardware", value: "Hinges, Locks, Handles Included" },
                    { name: "Finish", value: "Powder Coated Finish" },
                    { name: "Leaf", value: "Single and Double" },
                ]
            }, 
            {
                id: 52,
                name: "Fire Rated Steel Swing Door (Single and Double Leaf)",
                route: '/products/fire-rated-steel-swing-doors',
                cardImg: CFSD,
                cardDescription: "Safety Meets Elegance. Our Fire Rated Swing Door offers fire protection without compromising on style. It's the ideal choice for safety-conscious properties.",
                img: FRSD,
                paragraph1: "Fire-rated steel swing doors are an essential component of fire protection systems in various commercial and industrial settings. These doors are engineered to provide secure and fire-resistant access points while also facilitating swift evacuation in case of emergencies. Constructed from robust steel, they offer durability and fire resistance, with ratings that vary from 20 minutes to 3 hours, depending on the specific application and fire safety requirements. The swing-open design of these doors is efficient, allowing for quick and easy passage when open and forming a fire-resistant barrier when closed. They play a crucial role in containing fire and smoke, limiting the spread of flames to ensure the safety of occupants and protect property.",
                paragraph2: "Fire-rated steel swing doors are often equipped with fire-rated glass vision panels to maintain visibility and facilitate safe evacuation. They can be further enhanced with fire-rated hardware, seals, and automatic closing mechanisms that activate in response to fire alarms. These doors are designed to meet stringent fire safety standards and are commonly used in environments where fire risks are high, such as industrial facilities, hospitals, schools, and commercial buildings. Fire-rated steel swing doors are a reliable and critical element in fire safety systems, providing peace of mind and security during fire emergencies while also allowing for efficient daily operations in non-emergency situations.",
                features: [
                    {
                      feature: "Fire Rating Compliance",
                      description: "Fire-rated swing doors are designed to meet stringent fire safety standards, providing a specified level of fire resistance to protect against the spread of fire."
                    },
                    {
                      feature: "Single and Double Leaf Options",
                      description: "These doors are available in both single and double leaf configurations, offering flexibility to suit various architectural and space requirements."
                    },
                    {
                      feature: "Durability and Emergency Egress",
                      description: "Constructed with durable fire-resistant materials, these doors not only provide fire protection but also ensure safe emergency egress in the event of a fire, enhancing overall building safety."
                    }
                ],
                technicals: [
                    { name: "Door Material", value: "Fire-Rated Steel" },
                    { name: "Fire Rating", value: "1-hour | 2-hour" },
                    { name: "Swing Direction", value: "Inward Swing | Outward Swing" },
                    { name: "Door Size", value: "Standard Sizes Available" },
                    { name: "Hardware", value: "Fire-Rated Hinges, Locks, Handles Included" },
                    { name: "Finish", value: "Powder Coated Finish" },
                    { name: "Smoke and Draft Control", value: "Effective Smoke and Draft Seal" },
                ]
            },
            
            {
                id: 53,
                name: "Wooden Interior Doors",
                route: '/products/wooden-swing-doors',
                cardImg: CWID,
                cardDescription: "Enhance your interior with the warmth of Wooden Interior Doors. Crafted for both style and durability, these doors bring timeless elegance to your living spaces. Choose from various finishes and designs to match your decor perfectly and seamlessly.",
                img: WID,
                paragraph1: "Welcome to our exquisite collection of Interior Wooden Doors, where timeless craftsmanship meets the enduring beauty of solid oak and solid pine. Our doors are meticulously crafted to bring warmth, character, and a touch of nature into your living spaces. Whether you're looking to create a traditional ambiance with the rich, warm tones of oak or seeking the lighter and more versatile qualities of pine, our wooden doors provide a range of options to suit your unique interior design preferences. These doors are more than just functional; they are a statement piece, enhancing the overall aesthetics of your home while standing the test of time.",
                paragraph2: "Each Interior Wooden Door in our collection is a testament to the art of woodworking. Our doors, crafted from solid oak and solid pine, are not only pleasing to the eye but also built to last. The durability and elegance of these natural materials bring a sense of sophistication to your home, offering a rich backdrop for your interior decor. Whether you choose oak or pine, you can trust in the longevity and enduring charm of these doors, creating an inviting and cozy atmosphere within your living spaces that will be appreciated for years to come. Explore our selection and discover the perfect wooden door to transform your interior into a timeless masterpiece.",
                features: [
                    {
                      feature: "Timeless Elegance",
                      description: "Crafted from solid oak and solid pine, these doors exude timeless elegance, adding warmth and character to your interior spaces."
                    },
                    {
                      feature: "Versatile Design Options",
                      description: "Choose from a variety of designs, finishes, and wood types to match your decor perfectly, making these doors a versatile choice for any style of interior."
                    },
                    {
                      feature: "Long-Lasting Durability",
                      description: "Built to last, these wooden doors offer both durability and enduring beauty, ensuring they stand the test of time and provide lasting value to your home."
                    }
                  ],
                technicals: [
                    { name: "Door Material", value: "Solid Oak | Solid Pine" },
                    { name: "Wood Type", value: "Natural Oak | Knotty Pine" },
                    { name: "Finish", value: "Various Finish Options Available" },
                    { name: "Design Options", value: "Assorted Styles and Patterns" },
                    { name: "Durability", value: "Built for Longevity" },
                    { name: "Size Options", value: "Custom Sizes Available" },
                    { name: "Hinges and Hardware", value: "Quality Hinges and Hardware Included" },
                    { name: "Environmental Impact", value: "Sustainably Sourced Materials" },
                ]
            },
            
            {
                id: 54,
                name: "Sliding Doors",
                route: '/products/sliding-doors',
                cardImg: CSD,
                cardDescription: "Elevate your space with our modern Sliding Doors. Effortlessly merging form and function, these doors offer smooth transitions, sleek aesthetics, and practicality. Perfect for homes and businesses, they create an inviting atmosphere with a touch of contemporary style.",
                img: SLD,
                paragraph1: "Welcome to our exceptional selection of Sliding Doors, where style and function harmoniously converge. These doors have become synonymous with contemporary living, offering a seamless transition between spaces while adding a touch of modern sophistication to your surroundings. Perfect for both residential and commercial settings, our Sliding Doors provide a sense of openness and fluidity, allowing you to effortlessly connect and partition areas. Crafted with precision and using the finest materials, they not only elevate the aesthetics of your space but also offer ease of use and practicality. Whether you're looking to enhance your home's interior or redefine your commercial environment, our Sliding Doors offer an ideal blend of elegance and functionality.",
                paragraph2: "What sets our Sliding Doors apart is their versatility and adaptability. They are not just portals but design elements that create an inviting atmosphere while maximizing space utilization. With options ranging from frameless glass doors for a minimalist look to sturdy, insulated designs for energy efficiency, you can tailor these doors to your unique preferences and needs. Plus, our commitment to quality means these doors are built to withstand the test of time, ensuring you enjoy both form and function for years to come. Embrace the future of interior design with our exquisite Sliding Doors, and transform the way you experience and interact with your living and working spaces.",
                features: [
                    {
                      feature: "Space-Saving Design",
                      description: "Sliding doors are space-efficient, as they glide horizontally along tracks without the need for additional clearance, making them ideal for areas with limited space."
                    },
                    {
                      feature: "Contemporary Aesthetics",
                      description: "These doors offer a sleek and modern appearance, enhancing the overall interior design while allowing natural light to flow and create an open, inviting atmosphere."
                    },
                    {
                      feature: "Smooth and Silent Operation",
                      description: "Known for their quiet and smooth operation, sliding doors provide effortless access and are perfect for environments where noise disruption is a concern."
                    }
                  ],
                technicals: [
                    { name: "Door Material", value: "Variety of Options, Including Glass, Aluminum, or Wood" },
                    { name: "Size Options", value: "Custom Sizes Available" },
                    { name: "Track System", value: "High-Quality, Smooth-Gliding Track Mechanism" },
                    { name: "Frame Options", value: "Frameless | Framed" },
                    { name: "Security Features", value: "Locking Mechanisms for Enhanced Security" },
                    { name: "Insulation", value: "Insulated and Energy-Efficient Designs Available" },
                    { name: "Finish", value: "Multiple Finish and Color Options" },
                    { name: "Automation", value: "Optional Motorized or Smart Features" },
                ]
            } 
            
        ],
        
    },

    {
        id: 6, 
        category: 'Docking Systems',
        subCategory: [
            {
                id: 61,
                name: 'Dock Shelters',
                route: '/products/dock-shelters',
                cardImg: CDS,
                cardDescription: "Shield your workspace from the elements with our weather-resistant Dock Shelters, ensuring cargo and personnel protection.",
                paragraph1: "Welcome to our comprehensive range of Dock Shelters, where protection meets productivity. These innovative structures serve as a vital addition to your loading bay, creating a formidable barrier against the elements. Our Dock Shelters are meticulously designed to safeguard your workspace from wind, rain, and temperature fluctuations, ensuring optimal working conditions and cargo protection. With various customization options available, you can tailor the design to perfectly fit your specific needs, whether you operate in a bustling industrial environment or a commercial setting.",
                paragraph2: "One of the key advantages of our Dock Shelters lies in their ability to reduce energy costs. By forming a tight seal around the loading bay, they minimize heat loss and prevent unwanted drafts, ultimately contributing to a more energy-efficient and cost-effective operation. Whether you're dealing with perishable goods, sensitive equipment, or simply striving to create a more comfortable working environment for your team, our Dock Shelters play a pivotal role in maintaining the integrity of your cargo and ensuring that your loading and unloading processes run smoothly, regardless of the weather conditions outside.",
                subCategory: [
                    {
                        id: 611,
                        name: "Retractable Dock Shelter",
                        img: RDS,
                        paragraph1: "Our Retractable Dock Shelters offer a versatile solution for loading bays, providing adaptable and effective protection against the elements. Designed for modern logistics, these shelters efficiently accommodate a range of vehicle sizes and configurations. With their retractable design, they offer the flexibility to extend and retract as needed, ensuring a secure seal between the vehicle and the loading dock. This not only shields your workspace from adverse weather conditions but also contributes to energy savings by maintaining temperature control and minimizing drafts. Retractable Dock Shelters are a smart choice for businesses seeking both adaptability and weather protection for their loading bay operations.",
                        features: [
                            {
                              feature: "Adaptive Design",
                              description: "Retractable Dock Shelters offer adaptability to accommodate a variety of vehicle sizes and configurations, making them ideal for diverse loading operations."
                            },
                            {
                              feature: "Weather Protection and Energy Efficiency",
                              description: "These shelters provide effective protection against the elements, maintaining temperature control and reducing energy costs by minimizing drafts and heat loss."
                            },
                            {
                              feature: "Flexible Seal Mechanism",
                              description: "With their retractable design, these shelters ensure a secure seal between the vehicle and the loading dock, safeguarding cargo and workspace from adverse weather conditions while offering operational flexibility."
                            }
                          ]
                    },
                    {
                        id: 612,
                        name: "Fixed Dock Shelter",
                        img: FDS,
                        paragraph1: "Fixed Dock Shelters are the dependable choice for businesses seeking permanent weather protection and energy efficiency at their loading bays. These robust and enduring structures are securely anchored in place, forming a steadfast seal between the vehicle and the dock. By preventing heat loss and maintaining controlled environments, Fixed Dock Shelters contribute to reduced energy costs and increased productivity. Whether in the face of rain, wind, or temperature fluctuations, these shelters offer reliable protection, ensuring cargo integrity and creating comfortable working conditions, making them an excellent choice for businesses looking for a long-lasting and efficient solution at their loading docks.",
                        features: [
                            {
                              feature: "Permanent Weather Protection",
                              description: "Fixed Dock Shelters provide a durable and permanent barrier against adverse weather conditions, ensuring cargo protection and creating a comfortable working environment."
                            },
                            {
                              feature: "Energy Efficiency",
                              description: "By minimizing drafts and heat loss, these shelters contribute to energy efficiency, reducing operational costs and environmental impact."
                            },
                            {
                              feature: "Sturdy and Dependable Design",
                              description: "Anchored securely in place, Fixed Dock Shelters offer a sturdy and dependable solution, designed to withstand the rigors of frequent loading and unloading operations."
                            }
                          ]
                      },
                      
                      {
                        id: 613,
                        name: "Inflatable Dock Shelter",
                        img: IDS,
                        paragraph1: "Inflatable Dock Shelters are the epitome of adaptability and efficiency in loading bay solutions. Designed to accommodate various vehicle sizes and configurations, these shelters create a snug seal by inflating airbags around the docked vehicle. This inflatable design ensures a secure barrier against wind, rain, and temperature fluctuations, maintaining optimal working conditions. In addition to their versatile fit, Inflatable Dock Shelters offer energy efficiency by reducing drafts and heat loss, making them a sustainable choice for businesses aiming to minimize operational costs and environmental impact. Whether your loading bay deals with frequent changes in vehicle types or a need for airtight protection, Inflatable Dock Shelters provide the ideal solution for adapting to the ever-evolving demands of your loading operations.",
                        features: [
                            {
                              feature: "Adaptive Design",
                              description: "Inflatable Dock Shelters are designed to adapt to various vehicle sizes and configurations, ensuring a snug seal and effective protection for all loading scenarios."
                            },
                            {
                              feature: "Energy-Efficient Insulation",
                              description: "By reducing drafts and heat loss, these shelters contribute to energy efficiency, lowering operational costs and environmental impact while maintaining optimal working conditions."
                            },
                            {
                              feature: "Secure and Airtight Seal",
                              description: "Inflatable airbags create a secure barrier against adverse weather conditions, ensuring the protection of cargo and maintaining a comfortable working environment at the loading bay."
                            }
                          ]
                      } 
                ],
                technicals: [
                  { name: "Design Type", value: "Retractable | Inflatable | Fixed" },
                  { name: "Material", value: "High-Quality Materials for Durability" },
                  { name: "Size Options", value: "Custom Sizes Available" },
                  { name: "Energy Efficiency", value: "Reduces Heat Loss and Drafts (Inflatable and Fixed)" },
                  { name: "Security Features", value: "Locking Mechanisms for Enhanced Security (Fixed)"},
                  { name: "Installation", value: "Professional Installation Services Available" },
                  { name: "Customization", value: "Options for Custom Colors and Finishes" }
                ],     
            }, 
            {
                id: 62,
                name: "Dock Levelers",
                route: '/products/dock-levelers',
                cardImg: CDL,
                cardDescription: "Elevate efficiency with our robust Dock Levelers, bridging the gap for seamless loading and unloading.",
                img: DL,
                paragraph1: "Welcome to a world of elevated efficiency at your loading bay, powered by our Dock Levelers. These essential devices are the unsung heroes of efficient logistics, seamlessly connecting your warehouse with delivery vehicles. Crafted with precision engineering and built for durability, our Dock Levelers offer a safe and secure bridge for goods to flow between the dock and transport vehicles. They come in a variety of configurations, allowing you to tailor the system to your specific operational needs. Whether your business operates in a heavy industrial setting or a commercial space, our Dock Levelers ensure that every loading process is conducted with precision and safety in mind.",
                paragraph2: "One of the key advantages of our Dock Levelers is their contribution to minimizing the risk of accidents and cargo damage. By providing a stable, level surface for goods to travel, they not only enhance worker safety but also protect the integrity of your products during the loading and unloading process. Whether you deal with perishable goods, sensitive equipment, or a high volume of deliveries, our Dock Levelers are your trusted partners in streamlining your logistics, ensuring that every transaction is conducted with the utmost efficiency and care.",
                features: [
                    {
                      feature: "Efficient Cargo Flow",
                      description: "Dock Levelers ensure a smooth and efficient transfer of goods between the warehouse and transport vehicles, optimizing the loading and unloading process."
                    },
                    {
                      feature: "Safety and Accident Prevention",
                      description: "Designed to minimize the risk of accidents and cargo damage, these systems offer a stable, level surface for secure operations and enhanced worker safety."
                    },
                    {
                      feature: "Custom Configurations",
                      description: "Dock Levelers are available in a variety of configurations, allowing businesses to tailor the system to their specific operational needs and workspace requirements."
                    }
                  ],
                technicals: [
                    { name: "Design Type", value: "Mechanical | Hydraulic" },
                    { name: "Material", value: "Heavy-Duty Steel Construction" },
                    { name: "Weight Capacity", value: "upto 10 Ton" },
                    { name: "Size Options", value: "Tailored to Dock and Vehicle Dimensions" },
                    { name: "Safety Features", value: "Anti-Slip Surfaces, Safety Barriers, and Alerts" },
                    { name: "Customization", value: "Options for Colors and Finishes" },
                ]
            },
            {
              id: 63,
              name: "Scissor Lifts",
              route: '/products/scissor-lifts',
              cardImg: CSL,
              cardDescription: "Elevate your operations with our versatile Scissor Lifts, offering precision and efficiency in vertical mobility.",
              img: SL,
              paragraph1: "Welcome to a new dimension of vertical mobility with our Scissor Lifts. These versatile lifting platforms are designed to elevate your operations by providing a safe and efficient solution for accessing elevated workspaces. Whether you need to lift personnel, equipment, or materials, our Scissor Lifts are built with precision engineering to ensure reliability and safety in every use case.",
              paragraph2: "Our Scissor Lifts are suitable for various industries, including manufacturing, warehousing, construction, and maintenance. With customizable options and a range of lifting capacities, you can find the perfect Scissor Lift to meet your specific requirements. From compact models for confined spaces to heavy-duty lifts for industrial applications, our Scissor Lifts deliver the performance you need to enhance productivity and streamline your vertical mobility processes.",
              features: [
                {
                  feature: "Versatile Vertical Mobility",
                  description: "Scissor Lifts offer a versatile solution for accessing elevated workspaces, lifting personnel, equipment, or materials with precision and efficiency."
                },
                {
                  feature: "Customizable Options",
                  description: "Available in a range of models with customizable options, our Scissor Lifts can be tailored to meet specific industry needs and workspace constraints."
                },
                {
                  feature: "Safety and Reliability",
                  description: "Built with precision engineering, our Scissor Lifts prioritize safety and reliability, ensuring secure vertical mobility in various industries."
                }
              ],
              technicals: [
                { name: "Lift Type", value: "Hydraulic Scissor Lifts" },
                { name: "Lifting Capacity", value: "Varies based on model, up to X tons" },
                { name: "Platform Size", value: "Customizable platform dimensions" },
                { name: "Power Source", value: "Electric, Diesel, or Hybrid options" },
                { name: "Safety Features", value: "Emergency Stop, Safety Rails, and Overload Protection" },
                { name: "Applications", value: "Suitable for manufacturing, construction, warehousing, and maintenance tasks" },
              ]
            }
             
        ]
    },

    {
        id: 7, 
        category: 'Parking Systems',
        subCategory: [
            {
                id: 71,
                name: "Parking Management System",
                route: '/products/parking-management-systems',
                cardImg: CPMS,
                cardDescription: "Streamline parking operations with our Parking Management System. Real-time space availability, automated payments, and enhanced security make parking stress-free.",
                img: PMS,
                paragraph1: "Welcome to the future of parking management with our comprehensive Parking Management System. This all-encompassing solution combines cutting-edge technologies like Gate Barriers and Ticketing Systems to offer a seamless and efficient parking experience. For our clients, we provide flexibility in payment methods, allowing them to pay conveniently at Automatic Pay Stations or through a Manual Cashier. Whether you prefer automated transactions or a personal touch, our system caters to your specific needs, ensuring a hassle-free experience.",
                paragraph2: "Moreover, we introduce the convenience of a ticketless parking system. In this modern, paper-free approach, clients can enter the parking facility, complete their payment, and exit without the need for a physical ticket. This groundbreaking solution not only saves time but also reduces environmental impact by eliminating paper waste. Additionally, we offer the option to make payments online, making parking management even more convenient and accessible. With our Parking Management System, we aim to simplify and enhance the entire parking process, providing a range of choices for our clients and embracing the modern, digital era of parking solutions.",
                features: [
                    {
                      feature: "Gate Barriers and Ticketing Systems",
                      description: "Our system integrates Gate Barriers and Ticketing Systems for efficient control of vehicle entry, exit, and payment."
                    },
                    {
                      feature: "Multiple Payment Options",
                      description: "Clients can choose from Automatic Pay Stations, Manual Cashier payments, and even a modern paperless Ticketless Parking System with online payment for added convenience."
                    },
                    {
                      feature: "Real-time Space Management",
                      description: "Our system provides real-time information on available parking spaces, reducing congestion, and enhancing the overall parking experience for both clients and facility managers."
                    },
                    {
                      feature: "Ticketless Parking System",
                      description: "Experience the convenience of a modern, paper-free parking system, where clients can enter, pay, and exit without physical tickets and even make payments online."
                    }
                ],

                technicals: [
                    { name: "System Components", value: "Gate Barriers, Ticketing Systems, Automatic Pay Stations, Ticketless Parking System" },
                    { name: "Payment Methods", value: "Automatic Payment, Manual Cashier, Online Payment" },
                    { name: "Real-time Monitoring", value: "Space Availability, Vehicle Entry/Exit Status" },
                    { name: "User Interface", value: "User-Friendly and Intuitive Interfaces" },
                    { name: "Security Features", value: "Access Control, Surveillance, Secure Payment Processing" },
                    { name: "Customization Options", value: "Branding, Color Schemes, and User Experience Customization" },
                    { name: "Environmental Impact", value: "Paperless and Reduced Carbon Footprint" },
                ]
            },

            {
                id: 72,
                name: "Parking Guidance System",
                route: '/products/parking-guidance-systems',
                cardImg: CPGS,
                cardDescription: "Enhance parking efficiency with our Parking Guidance System. Real-time space guidance minimizes congestion and elevates the parking experience. Simplify parking management today.",
                img: PGS,
                paragraph1: "Welcome to a smarter, more efficient way to manage parking with our cutting-edge Parking Guidance System. Designed to transform the parking experience for drivers and facility managers alike, this system utilizes real-time sensors, LED displays, and data analytics to guide drivers to available parking spaces with precision. Whether you're navigating a bustling city center or a vast parking facility, our Parking Guidance System reduces stress and congestion, making the search for a parking spot a breeze. With an intuitive user interface accessible through mobile apps and on-site displays, it's never been easier for drivers to find and secure a parking space, and for facility managers to optimize space utilization and reduce operational costs.",
                paragraph2: "The versatility of our Parking Guidance System extends to its integration capabilities, ensuring compatibility with existing infrastructure and building management systems. Continuous updates on space availability provide a clear overview of parking conditions, while customization options allow for system configuration and branding alignment with your specific needs. For heightened security and environmental benefits, the system seamlessly integrates surveillance and access control while also contributing to reduced traffic congestion and emissions. Say goodbye to the frustration of aimlessly searching for parking spots and embrace the efficiency and convenience that our Parking Guidance System has to offer.",
                features: [
                    {
                      feature: "Real-time Guidance",
                      description: "Utilizes real-time sensors and LED displays to guide drivers to available parking spaces with precision, reducing the search time and minimizing congestion."
                    },
                    {
                      feature: "Intuitive User Interface",
                      description: "Offers an intuitive mobile app and on-site displays for easy navigation and space location, enhancing the overall parking experience for drivers."
                    },
                    {
                      feature: "Integration Capabilities",
                      description: "Ensures compatibility with existing infrastructure and building management systems, enabling a seamless and comprehensive parking solution."
                    },
                    {
                      feature: "Enhanced Safety and Security",
                      description: "Integrates surveillance and access control systems to provide enhanced safety and security for both drivers and the parking facility."
                    }
                  ],
                technicals: [
                    { name: "Guidance Technology", value: "Real-time Sensors (Ultrasonic and Camera Based), LED Displays, and Data Analytics" },
                    { name: "User Interface", value: "Intuitive Mobile Apps and On-site Displays" },
                    { name: "Integration Capabilities", value: "Compatibility with Existing Infrastructure and Building Management Systems" },
                    { name: "Space Monitoring", value: "Continuous Space Availability Updates" },
                    { name: "Customization Options", value: "System Configuration and Branding" },
                    { name: "Security Features", value: "Surveillance and Access Control Integration" },
                    { name: "Environmental Impact", value: "Reduced Traffic Congestion and Emissions" },
                ]
            }  
        ]
    },
    {
      id: 8,
      category: 'Bollards and Turnstiles',
      subCategory: [
        {
          id: 81,
          name: 'Bollards',
          route: '/products/bollards',
          cardImg: CBS,
          cardDescription: "Enhance security and control traffic with our range of Bollards. From fixed to removable and hydraulic options, we provide effective solutions tailored to your safety needs.",
          paragraph1: "Bollards play a crucial role in enhancing security and controlling traffic flow in various environments. Our range of Bollards offers effective solutions designed to meet diverse safety needs. Whether you require fixed barriers for permanent protection, removable bollards for flexible access control, or hydraulic bollards for dynamic security scenarios, our products are engineered for durability, reliability, and optimal performance.",
          paragraph2: "Each type of bollard serves a unique purpose, addressing specific security and traffic management requirements. From safeguarding storefronts and pedestrian areas to controlling vehicle access in sensitive zones, our bollards provide a robust physical barrier that enhances safety without compromising on aesthetics. Explore our range to find the ideal bollard solution that aligns with your security objectives and complements the architectural aesthetics of your space.",
          subCategory: [
            {
              id: 811,
              name: "Fixed Bollards",
              img: FXBD,
              paragraph1: "Fixed bollards offer a permanent and reliable solution for creating secure perimeters. Whether delineating pedestrian zones, protecting building entrances, or controlling traffic flow, fixed bollards provide a sturdy and visible barrier. Our fixed bollards are available in various materials and designs, allowing you to choose the option that best suits your security and aesthetic requirements.",
              features: [
                {
                  feature: "Permanent Security",
                  description: "Fixed bollards provide a permanent and robust security solution, ideal for protecting sensitive areas and controlling traffic flow."
                },
                {
                  feature: "Versatile Design",
                  description: "Available in various materials and designs, fixed bollards can be customized to match the architectural aesthetics of your space."
                },
                {
                  feature: "Durable Construction",
                  description: "Engineered for durability, our fixed bollards withstand environmental elements and provide long-lasting security."
                }
              ]
            },
            {
              id: 812,
              name: "Removable Bollards",
              img: RMBD,
              paragraph1: "Removable bollards offer flexibility in access control, allowing you to temporarily restrict or permit vehicle and pedestrian movement. Whether managing event spaces, securing parking areas, or adjusting traffic flow, removable bollards provide a versatile solution. Our range includes options with easy installation and removal mechanisms, ensuring efficient and adaptable security.",
              features: [
                {
                  feature: "Flexible Access Control",
                  description: "Removable bollards offer flexibility in controlling access, allowing you to adjust traffic flow as needed for events or changing security requirements."
                },
                {
                  feature: "Easy Installation",
                  description: "Designed for easy installation and removal, our removable bollards provide a convenient and adaptable solution for temporary access control."
                },
                {
                  feature: "Sturdy and Reliable",
                  description: "Built with durability in mind, our removable bollards provide reliable security when in use and easy removal when not needed."
                }
              ]
            },
            {
              id: 813,
              name: "Hydraulic Bollards",
              img: HYBD,
              paragraph1: "Hydraulic bollards offer dynamic and automated security, allowing you to control access with precision. Ideal for high-security zones, entrances, and sensitive areas, hydraulic bollards provide a sophisticated solution. Our range includes options with advanced features such as automatic raising and lowering, remote control, and integration with access control systems for seamless security management.",
              features: [
                {
                  feature: "Automated Security",
                  description: "Hydraulic bollards offer automated security solutions, with features such as automatic raising and lowering for efficient access control."
                },
                {
                  feature: "Advanced Control",
                  description: "Equipped with advanced features, including remote control and integration with access control systems, hydraulic bollards provide seamless security management."
                },
                {
                  feature: "High-Security Zones",
                  description: "Ideal for protecting high-security zones, entrances, and sensitive areas, hydraulic bollards offer a sophisticated and effective security solution."
                }
              ]
            }
          ],
          technicals: [
            { name: "Material", value: "Varies based on bollard type (Steel, Stainless Steel, etc.)" },
            { name: "Design Options", value: "Customizable designs to match architectural aesthetics" },
            { name: "Installation", value: "Fixed: Permanent installation; Removable: Easy installation/removal mechanisms; Hydraulic: Automated raising/lowering" },
            { name: "Control Options", value: "Remote control, Access control system integration (Hydraulic Bollards)" },
            { name: "Security Level", value: "Varies based on bollard type and features" },
            { name: "Applications", value: "Commercial properties, Public spaces, Event venues, High-security zones" }
          ]
        },

        {
          id: 82,
          name: 'Turnstiles',
          route: '/products/turnstiles',
          cardImg: CTS,
          cardDescription: "Control access with precision using our range of Turnstiles. From tripods to swing gates, optical turnstiles, and full-height options, our turnstiles ensure secure and efficient entry management.",
          paragraph1: "Turnstiles are a critical component in access control, providing a secure and efficient way to manage entry points. Our comprehensive range of turnstiles includes various types, each designed to cater to specific security requirements and space constraints. Whether you need compact tripod turnstiles, elegant swing gates, sophisticated optical turnstiles, or robust full-height turnstiles, our products are engineered for reliability, durability, and seamless access management.",
          paragraph2: "Each type of turnstile is crafted with precision engineering to offer not only effective access control but also to complement the aesthetics of your space. From sleek optical turnstiles suitable for modern office environments to rugged full-height turnstiles ideal for high-security zones, explore our turnstile solutions to find the perfect fit for your access management needs.",
          subCategory: [
            {
              id: 821,
              name: "Tripod Turnstiles",
              img: TPD,
              paragraph1: "Tripod turnstiles provide a compact and efficient solution for controlled access in various environments. With a three-arm design, these turnstiles offer a balance between space efficiency and effective access control. Ideal for areas with moderate foot traffic, our tripod turnstiles are easy to install, user-friendly, and customizable to match the aesthetic requirements of your space.",
              features: [
                {
                  feature: "Compact Design",
                  description: "Tripod turnstiles feature a three-arm design for space-efficient access control in areas with moderate foot traffic."
                },
                {
                  feature: "Easy Installation",
                  description: "Designed for easy installation and user-friendly operation, tripod turnstiles provide a convenient solution for controlled access."
                },
                {
                  feature: "Customizable Aesthetics",
                  description: "Customizable options allow you to match the appearance of tripod turnstiles with the aesthetic requirements of your space."
                }
              ]
            },
            {
              id: 822,
              name: "Swing Turnstile Gates",
              img: STG,
              paragraph1: "Swing gates offer a versatile and aesthetically pleasing solution for controlled access. With a swinging barrier design, these turnstiles provide efficient passage for users while enhancing the visual appeal of entrances. Suitable for a wide range of applications, swing gates are available in various materials and finishes to complement the architectural style of your space.",
              features: [
                {
                  feature: "Versatile Access",
                  description: "Swing gates provide a versatile solution for controlled access with a swinging barrier design for efficient user passage."
                },
                {
                  feature: "Aesthetic Appeal",
                  description: "Enhance the visual appeal of entrances with swing gates, available in various materials and finishes to complement architectural styles."
                },
                {
                  feature: "Wide Application",
                  description: "Suitable for a wide range of applications, swing gates offer efficient access control in diverse environments."
                }
              ]
            },
            {
              id: 823,
              name: "Optical Turnstiles",
              img: OPTR,
              paragraph1: "Optical turnstiles combine advanced technology with sleek design to offer secure and sophisticated access control. With infrared sensors and modern aesthetics, optical turnstiles provide an elegant solution for corporate offices, upscale buildings, and high-end facilities. Their bi-directional functionality and customizable finishes make them an ideal choice for modern access management.",
              features: [
                {
                  feature: "Advanced Technology",
                  description: "Optical turnstiles feature infrared sensors and advanced technology for secure and sophisticated access control."
                },
                {
                  feature: "Sleek Design",
                  description: "With modern aesthetics, optical turnstiles offer an elegant solution suitable for corporate offices, upscale buildings, and high-end facilities."
                },
                {
                  feature: "Bi-Directional Functionality",
                  description: "Bi-directional functionality enhances the efficiency of user passage, making optical turnstiles an ideal choice for modern access management."
                }
              ]
            },
            {
              id: 824,
              name: "Full-Height Turnstiles",
              img: FHTR,
              paragraph1: "Full-height turnstiles provide maximum security and control in high-risk zones. With a full-height barrier, these turnstiles prevent unauthorized access and offer robust security. Commonly used in critical infrastructure, stadiums, and industrial facilities, full-height turnstiles come with customization options for enhanced security features and finishes to suit specific security needs.",
              features: [
                {
                  feature: "Maximum Security",
                  description: "Full-height turnstiles provide maximum security with a full-height barrier, preventing unauthorized access in high-risk zones."
                },
                {
                  feature: "Robust Security",
                  description: "Ideal for critical infrastructure, stadiums, and industrial facilities, full-height turnstiles offer robust security and control."
                },
                {
                  feature: "Customization Options",
                  description: "Customization options allow for enhanced security features and finishes tailored to specific security needs."
                }
              ]
            }
          ],
          technicals: [
            { name: "Material", value: "Varies based on turnstile type (Steel, Stainless Steel, etc.)" },
            { name: "Design Options", value: "Customizable designs to match architectural aesthetics" },
            { name: "Installation", value: "Varies based on turnstile type (Compact for Tripod, Swinging for Swing Gates, etc.)" },
            { name: "Control Options", value: "Bi-directional control for most turnstile types" },
            { name: "Security Level", value: "Varies based on turnstile type and features" },
            { name: "Applications", value: "Commercial properties, Office buildings, Public spaces, High-security zones" }
          ]
        }
        
        

      ]
    }
]

export default products

