import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import About from '../../Assets/Images/About-2.jpg'
import AboutMobile from '../../Assets/Images/About-Mobile.jpeg'
import { useNavigate } from 'react-router-dom';

const Company = () => {

    const isSmallScreen = useMediaQuery('(max-width:900px)');

    const navigate = useNavigate();

    const linkToPage = (e) => {
        e.preventDefault() ; 
        navigate('/products') ; 
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const products = [
        "Roller Shutters",
        "Automatic Sliding Doors",
        "Steel Doors",
        "Industrial Overhead Doors",
        "Garage Doors",
        "Gate Barriers",
        "Sliding Gates",
        "Swing Gates",
        "Palisade Fencing",
        "Parking Systems",
        "Docking Systems",
        "Bollards",
        "Turnstiles"
      ];
    
      // Split the products into two columns
      const column1 = products.slice(0, 7);
      const column2 = products.slice(6);

    return (
        <Container maxWidth="xl" sx={{ my: 4 }}>
            <Paper elevation={3} style={{ padding: '3vh', paddingBottom: '5vh' }}>
                <Typography variant="h5" align="center" gutterBottom sx={{ my: 5 }}>
                    About Us
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        {/* Left side (Image) */}
                        <Box display="flex" justifyContent="center" alignItems='center'>
                            { isSmallScreen || <img src={About} alt="about-img" style={{ maxWidth: '100%', height: 'auto' }} /> }
                            { isSmallScreen && <img src={AboutMobile} alt="about-img" style={{ maxWidth: '100%', height: 'auto' }} /> }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ py: 5}}>
                        {/* DESCRIPTION */}
                        <Typography variant="body1" paragraph>
                            Welcome to Opend Entrance Systems, your trusted partner for premium entrance solutions in the United Arab Emirates. With deep roots in the UAE, 
                            our experienced engineers have played pivotal roles in numerous regional projects. 
                            We are dedicated to providing a wide range of high-quality entrance products that enhance security, convenience, and aesthetics.
                            Our commitment to excellence, reliability, and innovation makes us the ideal choice for all your entrance needs.
                        </Typography>

                        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.2rem', letterSpacing: '0.1rem' }}> Our Products </Typography>
                        <Typography variant="body1" paragraph>
                            We offer a diverse range of high-quality entrance products:
                                
                            <Grid container spacing={2}>
                                {/* First column */}
                                <Grid item xs={12} sm={6}>
                                    <ul>
                                    {column1.map((product, index) => (
                                        <li key={index} style={{ fontWeight: 'bold', fontSize: '0.9rem'}}>{product}</li>
                                    ))}
                                    </ul>
                                </Grid>
                                {/* Second column */}
                                <Grid item xs={12} sm={6}>
                                    <ul>
                                    {column2.map((product, index) => (
                                        <li key={index} style={{ fontWeight: 'bold', fontSize: '0.9rem'}}>{product}</li>
                                    ))}
                                    </ul>
                                </Grid>
                            </Grid>

                            <b>These comprehensive solutions are tailored to your unique requirements!</b>

                        </Typography>
                        
                        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.2rem', letterSpacing: '0.1rem' }}> A Trusted Partner </Typography>
                        <Typography variant="body1" paragraph>
                            We understand that trust is paramount when choosing an entrance systems provider. Our proven track record of successfully completed projects 
                            and our team's expertise make us a reliable partner for your entrance projects. Open’d Entrance Systems is dedicated to ensuring the security, 
                            functionality, and aesthetic appeal of your entrances, making us the preferred choice for clients who demand the best.
                        </Typography>
                        
                        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.2rem', letterSpacing: '0.1rem' }}> Experience the difference </Typography>
                        <Typography variant="body1" paragraph>
                        We invite you to explore our wide range of products and experience the difference that quality and expertise make. Trust us to safeguard your entrances, 
                        and we'll open the door to a more secure and convenient future for you. For inquiries, consultations, or to discuss your project, 
                        please do not hesitate to contact us. We look forward to serving you and providing the entrance solutions that your property deserves.
                        </Typography>

                            <Button
                                variant="plain"
                                endDecorator={<ArrowForwardIcon fontSize="sm" />}
                                sx={{
                                my: 2,    
                                '&:hover': { '--Button-gap': '0.625rem', background: '#46547A' },
                                '& span': { transition: '0.15s' },
                                background: '#2E3B55',
                                color: 'white'
                                }}
                                onClick={(e) => linkToPage(e)}
                            >
                                EXPLORE OUR LIST OF PRODUCTS

                            </Button>

                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default Company;
