import Craftmanship from '../Assets/Images/Craftmanship.jpg'
import Expertise from '../Assets/Images/Expertise.jpg'
import Customized from '../Assets/Images/Customized.jpg'

import Installation from '../Assets/Images/Installation.jpg'
import TC from '../Assets/Images/Testing and Commissioning.jpg'
import Maintenance from '../Assets/Images/Maintenance.jpg'


const choice = [
    {
        id: '01',
        heading: 'Our Core Business Pillars',
        points: [
            {
                id: '11',
                heading: 'Quality Craftsmanship',
                img: Craftmanship,
                paragraph: 'Our unwavering commitment to quality craftsmanship ensures that every product we produce is a testament to precision and durability, providing security and aesthetics that stand the test of time.'
            },
            {
                id: '12',
                heading: 'Expertise in Design Innovation',
                img: Expertise,
                paragraph: "Our engineering team's expertise in product design and innovation not only meets industry standards but also redefines them, setting new benchmarks in style, functionality, and efficiency."
            },
            {
                id: '13',
                heading: 'Customization Excellence',
                img: Customized,
                paragraph: 'Our skilled engineering team excels in tailoring door solutions to match the unique vision and requirements of our clients, offering a spectrum of customization options that transform their concepts into reality.'
            },
        ]

    },

    {
        id: '02',
        heading: 'Our Services',
        points: [
            {
                id: '21',
                heading: 'Installation',
                img: Installation,
                paragraph: 'Our dedicated team provides professional installation services, ensuring the flawless setup and integration of your systems or equipment. We take care of the technical aspects, so you can focus on what matters most—your operations.'
            },
            {
                id: '22',
                heading: 'Testing and Commissioning',
                img: TC,
                paragraph: "We go the extra mile with rigorous testing and precise commissioning, guaranteeing that your systems perform optimally and adhere to the highest safety standards. We leave no room for uncertainty, delivering solutions that you can trust."
            },
            {
                id: '33',
                heading: 'Maintenance and Services',
                img: Maintenance,
                paragraph: 'With a commitment to your continued success, we offer dependable, ongoing maintenance and support. Our services keep your systems running smoothly and efficiently, reducing downtime and enhancing the longevity of your investments.'
            },
        ]

    }
]

export default choice