import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from 'react-bootstrap/Table';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";


const ProductDetails = ({ name, paragraph1, paragraph2, subCategory, img, features, technicals }) => {

    const navigate = useNavigate();
    
  return ( 
    <Container maxWidth='xl' sx={{ my: 5 }}>

        <Button variant="outlined" onClick={() => navigate('/products')}> <ArrowBackIosIcon sx={{ fontSize: '1.25vh'}} /> Back </Button>

        <Typography variant="h5" align="center" gutterBottom sx={{ my: 5, letterSpacing: '0.1rem', textTransform: 'uppercase' }}>
            {name}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row'}, alignItems: 'center', textAlign: 'justify' }}>
            <Box>
                <Typography variant="body1" paragraph>
                    {paragraph1}
                </Typography>

                <Typography variant="body1" paragraph>
                    {paragraph2}
                </Typography>
            </Box>

           
            {
                img && (
                    <Box sx={{ p: 4, ml: 5 , display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={img} alt='product-img' style={{ height: '45vh', borderRadius: '2vh' }} />
                    </Box>
                )
            }
           

        </Box>

        {
            subCategory && subCategory.map(product => {
                return (
         
                    <Box sx={{ display: 'flex', m: {xs: 3, md: 2} , flexDirection: { xs: 'column', md: 'row'}, borderBottom: '1px solid lightgrey'}}>

                        <Box>
                            <Typography variant="h6" gutterBottom sx={{ my: 2, letterSpacing: '0.1rem', textTransform: 'uppercase' }}>
                                {product.name}
                            </Typography>

                            <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                                {product.paragraph1}
                            </Typography>

                            <Typography variant="h6" sx={{ my: 2 }} >
                                Key Features
                            </Typography>

                            {
                                product.features.map(f => {
                                    return(
                                        <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                                            <b>{f.feature}: </b> 
                                            {f.description}
                                        </Typography>
                                    )
                                })
                            }

                        </Box>


                        <Box sx={{ p: 4, ml: { md: 5} , display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {/* <CircularProgress /> */}
                            <img src={product.img} alt='product-img' style={{ height: '45vh', borderRadius: '2vh'}} />
                        </Box>

                    </Box>

      
                )
            })
        }

        {
            features && (
                <Box>
                    <Typography variant="h6" sx={{ my: 2 }} >
                        Key Features
                    </Typography>
                    {
                        features.map(f => {
                            return(
                                <Typography variant="body1" paragraph>
                                    <b>{f.feature}: </b> 
                                    {f.description}
                                </Typography>
                            )
                        })
                    }

                </Box>
            )
        }

        <Typography variant="h6" gutterBottom sx={{ mt: 5, fontWeight: 'bold' }}> Technical Data* </Typography>

        <Table striped>
            <thead>
                <tr>
                    <th>S. No</th>
                    <th>Accessory</th>
                    <th>Data</th>
                </tr>
            </thead>
            <tbody>
                {
                    technicals.map( (item, index) => {
                        return(
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.value}</td>
                            </tr>
                        )
                    })
                }

            </tbody>
        </Table>


        <Typography variant="caption" gutterBottom sx={{ my: 2, color: 'darkgrey'}}> * Refer official datasheets for more precise details </Typography>

    </Container>
   );
}
 
export default ProductDetails;