import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';



const BlogCard = ({id, title, cardDescription, date, img }) => {
    const navigate = useNavigate();


    const linkToPage = (e) => {
        e.preventDefault() ; 
        navigate(`/blog/post-${id}`) ; 
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return ( 
        <Card sx={{ maxWidth: 345, cursor: 'pointer', my: 3, transition: 'transform 0.5s, box-shadow 0.5s', '&:hover': { transform: 'scale(1.02)', 
                    boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)'} }} onClick={(e) => linkToPage(e)} >
            <CardActionArea>
                <CardMedia
                component="img"
                height="140"
                image={img}
                alt="blog-img"
                />
                <CardContent sx={{ py: 2 }}>
                    <Typography gutterBottom variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {cardDescription}
                    </Typography>

                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <CalendarMonthIcon sx={{ mr: 2}} />
                        <Typography variant="caption" color="text.secondary">
                            {date}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
     );
}
 
export default BlogCard;