import Services from '../Assets/Images/Services.jpg'
import AMC from '../Assets/Images/AMC.jpg'
import CMC from '../Assets/Images/CMC.jpg'

const services = [
  {
    sectionName: "Services",
    image: Services,
    points: [
      {
        title: "Product Installation and Setup",
        description: "Our expert technicians will ensure a hassle-free installation and setup of your products, so you can start using them right away. We handle everything from unboxing to configuration."
      },
      {
        title: "Repairs and Maintenance",
        description: "When your equipment or appliances need fixing, we're here to help. Our skilled technicians will diagnose and repair any issues to get your products back in optimal working condition."
      },
      {
        title: "Product Upgrades and Customization",
        description: "Stay up-to-date with the latest technology. We offer product upgrades and customization services to enhance the performance and features of your equipment."
      },
      {
        title: "Troubleshooting and Technical Support",
        description: "Experiencing technical difficulties? Our support team is available to assist you with any issues you encounter, providing comprehensive troubleshooting and technical support."
      },
      {
        title: "Consultation and Product Recommendations",
        description: "If you're not sure which products are right for your needs, our team of experts can provide consultation and recommend the best solutions tailored to your requirements."
      },
      {
        title: "Training and Workshops",
        description: "We offer training sessions and workshops to help you and your team make the most of your products. Learn to use them efficiently and unlock their full potential."
      }
    ]
  },
  {
    sectionName: "AMC",
    image: AMC,
    points: [
      {
        title: "Preventive Maintenance",
        description: "With our AMC, you can ensure your products are regularly serviced and maintained, preventing potential issues and extending their lifespan."
      },
      {
        title: "Priority Support",
        description: "AMC customers receive priority support, meaning your service requests are handled with the highest priority, reducing downtime and inconvenience."
      },
      {
        title: "Reduced Costs",
        description: "Save on repair and maintenance costs. AMC customers enjoy discounted rates on services, spare parts, and accessories."
      },
      {
        title: "Periodic Health Checks",
        description: "We perform periodic health checks to identify and address any issues before they become major problems."
      }
    ]
  },
  {
    sectionName: "CMC",
    image: CMC,
    points: [
      {
        title: "All-Inclusive Service",
        description: "Our CMC offers an all-inclusive maintenance and support package, covering all repair, maintenance, and support needs for your products."
      },
      {
        title: "Equipment Replacement",
        description: "In the event of irreparable damage or equipment failure, CMC customers are eligible for equipment replacement, minimizing downtime."
      },
      {
        title: "24/7 Support",
        description: "Enjoy round-the-clock support to address emergencies and urgent issues, ensuring your products remain operational at all times."
      },
      {
        title: "Predictive Maintenance",
        description: "Our advanced analytics and monitoring systems allow us to predict potential issues and proactively address them, ensuring maximum product uptime."
      }
    ]
  }
]


export default services